import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  ActiveElement,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import { getAnswersByProject, getAnswersWithPaginationByProject } from "services/answerService";
import { useParams } from "react-router-dom";
import { handleError } from "utils/handleError";
import { Answer } from "interfaces/Answer";
import { Loading } from "utils/Loading";
import { isAuthenticated } from "services/authService";
import InfoModalComponent from "./modals/InfoModalComponent";
import { ChartData } from "interfaces/ChartData";
import AnswerTableComponent from "components/answers/AnswerTableComponent";
import PaginationComponent from "components/layout/nav/PaginationComponent";
import { Pagination } from "interfaces/Pagination";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

function ScatterplotComponent() {
  const authenticated = isAuthenticated();
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ChartData | null>(null);
  const [pagination, setPagination] = useState<Pagination>();

  const openInfoModal = (answer: ChartData) => {
    setModalData(answer);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setModalData(null);
    setOpenModal(false);
  };
  // State pour stocker les données du graphique et les noms de catégories
  const [answersWithCategorySum, setAnswersWithCategorySum] = useState<
    ChartData[] | undefined
  >(undefined);
  const [categoryNames, setCategoryNames] = useState<string[]>([]);

  const fetchData = async (page?: number) => {
    try {
      if (!projectId) {
        return;
      }

      const response = await getAnswersWithPaginationByProject(projectId, page);
      const answersData: Answer[] = response.data;
      setPagination(response.meta);

      // Transfation des réponses en données utilisables pour le graphique
      const answersWithSum = answersData.map((answer: Answer) => {
        const data: { [categoryId: string]: number } = {};

        // Parcoure les réponses de chaque question pour agréger les valeurs par catégorie
        Object.values(answer.answers).forEach((response) => {
          const categoryName = response.category_name;
          const responseValue = response.response;
          if (data[categoryName]) {
            data[categoryName] += responseValue;
          } else {
            data[categoryName] = responseValue;
          }
        });

        // On renvoi un objet avec l'email et les données agrégées
        return { ...answer, data };
      });

      // Maj du state avec les données du graphique
      setAnswersWithCategorySum(answersWithSum);

      // On extraie les noms de catégorie de la première réponse
      const firstAnswer = answersWithSum[0];
      if (firstAnswer) {
        const names = Object.keys(firstAnswer.data);
        setCategoryNames(names);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const userEmailToHighlight = localStorage.getItem("answerEmail");

  const options = {
    scales: {
      x: {
        min: 0,
        max: 60,
        title: {
          display: true,
          text: categoryNames[0], // On utilise le premier nom de catégorie comme titre de l'axe x
        },
        grid: {
          lineWidth: (context: any) => (context.tick.value === 30 ? 3 : 1), // Ajustez la largeur de la ligne de la grille de l'axe x
        },
      },
      y: {
        min: 0,
        max: 60,
        title: {
          display: true,
          text: categoryNames[1], // On utilise le deuxième nom de catégorie comme titre de l'axe y
        },
        grid: {
          lineWidth: (context: any) => (context.tick.value === 30 ? 3 : 1), // Ajustez la largeur de la ligne de la grille de l'axe x
        },
      },
    },
    elements: {
      point: {
        radius: 5,
      },
    },
    plugins: {
      tooltip: {
        enabled: authenticated ? true : false,
        callbacks: {
          label: (context: any) => {
            const answer = answersWithCategorySum
              ? answersWithCategorySum[context.dataIndex]
              : null;

            // Retournez l'email dans le label du tooltip
            return answer ? `${answer.email}` : "";
          },
        },
      },
    },
    onClick: (event: any, elements: ActiveElement[]) => {
      if (authenticated) {
        if (elements && elements.length > 0) {
          const index = elements[0].index;
          const answer = answersWithCategorySum
            ? answersWithCategorySum[index]
            : null;

          if (answer) {
            openInfoModal(answer);
          }
        }
      }
    },
    onHover: (event: any, elements: ActiveElement[]) => {
      if (authenticated) {
        elements.length > 0
          ? (event.chart.canvas.style.cursor = "pointer")
          : (event.chart.canvas.style.cursor = "default");
      }
    },
  };

  // Données du graphique
  const chartData = {
    datasets: [
      {
        label: "Réponses du questionnaire",
        data:
          answersWithCategorySum?.map((answer) => ({
            x: Object.values(answer.data)[0] || 0,
            y: Object.values(answer.data)[1] || 0,
          })) || [],
        backgroundColor: answersWithCategorySum?.map((answer) =>
          answer.email === userEmailToHighlight
            ? "#2BD6D9"
            : authenticated
            ? "rgb(255, 99, 132)"
            : "grey"
        ),
      },
    ],
  };

  // Rendu du composant avec le graphique Scatter
  return (
    <div>
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="w-full p-6">
            <Scatter options={options} data={chartData} />
          </div>
        )}
        {openModal && modalData && (
          <InfoModalComponent
            data={modalData}
            isOpen={openModal}
            onClose={handleCloseModal}
          />
        )}
      </div>
      {authenticated && (
        <>
          <AnswerTableComponent answers={answersWithCategorySum || []} />
          {pagination && <PaginationComponent pagination={pagination} fetchData={fetchData} />}

        </>
      )}
    </div>
  );
}

// Exportez le composant ScatterplotComponent par défaut
export default ScatterplotComponent;
