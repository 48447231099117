import CategoryFormComponent from "components/categories/CategoryFormComponent";
import React from "react";

function CategoryCreatePage() {
  return (
    <div className="w-full">
      <h1 className="p-6 text-2xl font-semibold flex-1">Créer une catégorie</h1>
      <CategoryFormComponent />
    </div>
  );
}
export default CategoryCreatePage;
