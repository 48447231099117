import React from "react";


import {UseFormRegisterReturn, FieldValues, FieldError, DeepMap} from 'react-hook-form';

interface MappingArray {
    id: string;
    name: string;
}

interface SelectComponentProps<T extends FieldValues = FieldValues> {
    name: string;
    selectMap: MappingArray[];
    isDisabled?: boolean;
    haveEmptyEntry?: boolean;
    selected: string;
    registerProps: UseFormRegisterReturn;
}

function SelectComponent<T>({selectMap, selected, registerProps,  name, isDisabled=false, haveEmptyEntry=true} : SelectComponentProps)
{
    const [value, setValue] = React.useState("");

    const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
        setValue(e.target.value as string);
    };

    if (value === "" && selected !== "") {
        setValue(selected);
    }

    return (
        <div>
            <select
                {...registerProps}
                value={value}
                className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none mt-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6"
                disabled={isDisabled}
                onChange={handleChange}
            >
                {haveEmptyEntry ? (
                    <option value="" disabled>
                        {name}
                    </option>
                ):""}

                {selectMap.map((entries) => (
                    <option key={entries.id} value={entries.id}>
                        {entries.name}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default SelectComponent;