import React from "react";

import {UseFormRegisterReturn, FieldValues, FieldError, DeepMap} from 'react-hook-form';

interface MappingArray {
    id: string;
    name: string;
}

interface SelectQuestionComponentProps<T extends FieldValues = FieldValues> {
    label: string;
    required: boolean;
    registerProps: UseFormRegisterReturn;
    name: string;
    index: number;
    selectMap: MappingArray[];
    htmlFor: string;
    errors: DeepMap<T, FieldError>;
    isDisabled?: boolean;
    defaultValue: string;
}

function SelectQuestionComponent<T>({htmlFor, required, label, index, selectMap, defaultValue, errors,  registerProps, name, isDisabled=false} : SelectQuestionComponentProps)
{
    return (
        <div className="flex flex-col">
            <label
                htmlFor={htmlFor}
                className="text-sm font-medium leading-6 text-gray-900"
            >
                {label}
                {required && (<span className="text-red"> *</span>) }
            </label>

            <select
                {...registerProps}
                defaultValue={defaultValue}
                className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none mt-2 rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6"
                disabled={isDisabled}
            >
                <option value="" disabled>
                    {name}
                </option>

                {selectMap.map((entries) => (
                    <option key={entries.id} value={entries.id}>
                        {entries.name}
                    </option>
                ))}
            </select>
            <p className="text-red">
                {errors?.questions?.[index]?.category_id?.message}
            </p>
        </div>
    );
}

export default SelectQuestionComponent;