import React from "react";
import HeaderComponent from "components/layout/HeaderComponent";
import SidebarComponent from "components/layout/SidebarComponent";
import { Outlet } from "react-router-dom";
import FooterComponent from "components/layout/FooterComponent";
import ScrollToTop from "utils/ScrollToTop";

interface RootProps {
  children: React.ReactNode;
  authenticated: boolean | null;
  role: string | null;
}

function Root({ children, authenticated, role }: RootProps) {
  return (
    <div className="flex flex-col min-h-screen">
      <ScrollToTop />
      <HeaderComponent />
      <div className="flex-1 flex">
        {authenticated && <SidebarComponent role={role} />}
        <div className="flex-1 p-4 overflow-y-auto overflow-x-hidden">
          {children}
          <Outlet />
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}

export default Root;
