import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import LoginForm from "../../form-models/LoginForm";
import { useNavigate } from "react-router-dom";
import { login } from "services/authService";
import { ValidationError, handleError } from "utils/handleError";

// Définir le schéma de validation avec Yup
const loginSchema = yup.object().shape({
  email: yup.string().required("Un email est requis"),
  password: yup.string().required("Un mot de passe est requis"),
});

interface LoginFormProps {
    isModal: boolean;
    onClose?: () => void;
    onLoginSuccess?: () => void;
}

function LoginFormComponent({isModal, onClose, onLoginSuccess}: LoginFormProps) {
  const [errValidation, setErrValidation] = useState<ValidationError | null>(
    null
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({
    resolver: yupResolver(loginSchema),
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    try {
      const response = await login(data);
      
      if (!response?.access_token) return; // Si la connexion échoue, on ne fait rien
      
      // Si c'est une modale, on ferme la modale
      if (isModal && onClose) {
        onClose();
      }
  
      // On appelle onLoginSuccess si elle est définie
      if (onLoginSuccess) {
        onLoginSuccess();
      }
  
      navigate("/project");
  
    } catch (error) {
      const errorMessages = handleError(error);
      setErrValidation(errorMessages);
    }
  };
  
  return (
    <form
        className="space-y-6"
        action="#"
        method="POST"
        onSubmit={handleSubmit(onSubmit)}
    >
        <div>
        <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 text-gray-900"
        >
            Adresse email <span className="text-red">*</span>
        </label>
        <div className="mt-2">
            <input
            {...register("email")}
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6"
            />
            <p className="text-red">{errors.email?.message}</p>
            {errValidation?.errors?.email && (
            <p className="text-red">{errValidation.errors.email[0]}</p>
            )}
        </div>
        </div>
        <div>
        <div className="flex items-center justify-between">
            <label
            htmlFor="password"
            className="block text-sm font-medium leading-6 text-gray-900"
            >
            Password <span className="text-red">*</span>
            </label>
        </div>
        <div className="mt-2">
            <input
            {...register("password")}
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6"
            />
            <p className="text-red">{errors.password?.message}</p>
            {errValidation?.errors?.password && (
            <p className="text-red">{errValidation.errors.password[0]}</p>
            )}
        </div>
        </div>
        <div>
        <button
            type="submit"
            className="flex w-full justify-center rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
            Se connecter
        </button>
        </div>
    </form>
  );
}
export default LoginFormComponent;
