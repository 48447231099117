import CategoryForm from "form-models/CategoryForm";
import { getByUrl, getFullResponseByUrl, postByUrl, updateByUrl } from "./services";

const categoryUrl = "api/category";

export const getAllCategories = async () => {
  try {
    return getByUrl(categoryUrl);
  } catch (error) {
    throw error;
  }
};

export const getAllCategoriesWithPagination = async (page?:number) => {
  try {
    return getFullResponseByUrl(`${categoryUrl}${page !== undefined ? `?page=${page}` : ''}`);
  } catch (error) {
    throw error;
  }
};

export const getCategoryById = async (categoryId: string) => {
  try {
    const url = `${categoryUrl}/${categoryId}`;
    return getByUrl(url);
  } catch (error) {
    throw error;
  }
};

export const createCategory = async (categoryData: CategoryForm) => {
  try {
    return postByUrl(categoryUrl, categoryData);
  } catch (error) {
    throw error;
  }
};

export const updateCategory = async (
  categoryData: CategoryForm,
  categoryId: string
) => {
  try {
    const url = `${categoryUrl}/${categoryId}`;
    return updateByUrl(url, categoryData);
  } catch (error) {
    throw error;
  }
};
