import React from "react";

import {FieldError, FieldErrors, FieldValues} from 'react-hook-form';
import {ValidationError} from "utils/handleError";

interface ErrorComponentProps<T extends FieldValues = FieldValues> {
    name: string;
    errors: FieldErrors<T>;
    errValidation: ValidationError | null;
}

function ErrorForm<T>({name, errors, errValidation}: ErrorComponentProps) {
    const fieldError = errors[name] as FieldError | undefined;
    return (
        <div className="errorForm">
            {fieldError && <p className="text-red">{fieldError?.message}</p>}
            {errValidation && errValidation.errors && errValidation.errors[name] && (
                <p className="text-red">{errValidation.errors[name][0]}</p>
            )}
        </div>

    );
}

export default ErrorForm;