import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ChartData } from "interfaces/ChartData";

interface MyModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: ChartData;
}

function InfoModalComponent({ isOpen, onClose, data }: MyModalProps) {
  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0 bg-black/50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center bg-black/15 overflow-y-auto">
            <Dialog.Panel className="max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle transition-all">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Information du répondant :
              </Dialog.Title>
              <div className="my-5">
                <p className="text-ml text-gray-500 m-2">
                  Email:<span className="font-bold"> {data.email}</span>
                </p>
                <p className="text-ml text-gray-500 m-2">
                  Prénom:<span className="font-bold"> {data.firstname}</span>
                </p>
                <p className="text-ml text-gray-500 m-2">
                  Nom:<span className="font-bold"> {data.lastname}</span>
                </p>
                <p className="text-ml text-gray-500 m-2">
                  Poste ou rôle dans le projet:
                  <span className="font-bold"> {data.position}</span>
                </p>
              </div>

              <div className="mt-4 flex flex-col items-center">
                <button
                  onClick={onClose}
                  className="w-1/3 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-3 py-1.5 text-sm font-semibold text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                >
                  Retour
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default InfoModalComponent;
