import React from "react";
import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
  element: React.ReactNode;
  allowUnauthenticated?: boolean;
  authenticated: boolean | null;
  role: string | null;
  onlyAdmin: boolean | null;
}

const PrivateRoute = ({
                        authenticated,
                        element,
                        allowUnauthenticated,
                        role,
                        onlyAdmin
                      }: PrivateRouteProps) => {

  if (authenticated || allowUnauthenticated) {
      if (onlyAdmin && role !== "Super Admin" && !allowUnauthenticated) {
          return <Navigate to="/project" />
      }
    return <>{element}</>;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;