import React from "react";
import QuizTableComponent from "components/quizzes/QuizTableComponent";

function QuizPage() {
  return (
    <div className="w-full">
      <h1 className="p-6 text-2xl font-semibold flex-1">
        Liste des questionnaires
      </h1>
      <QuizTableComponent />
    </div>
  );
}
export default QuizPage;
