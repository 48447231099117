import ProfilFormComponent from "components/profil/ProfilFormComponent";
import React from "react";

function ProfilPage() {
  return (
    <div className="w-full">
      <h1 className="p-6 text-2xl font-semibold flex-1">
        Informations personnelles
      </h1>
      <ProfilFormComponent />
    </div>
  );
}
export default ProfilPage;
