import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Quiz } from "interfaces/Quiz";
import { deleteQuiz } from "services/quizService";
import { toast } from "react-toastify";
import { handleError } from "utils/handleError";

interface MyModalProps {
  closeModal: () => void;
  onDeleteModalConfirm: () => void;
  quiz: Quiz;
}

function QuizDeleteModalComponent({
  closeModal,
  onDeleteModalConfirm,
  quiz,
}: MyModalProps) {
  const submitDelete = async () => {
    if (quiz.id) {
      try {
        await deleteQuiz(quiz.id);
        onDeleteModalConfirm();
        closeModal();
      } catch (error) {
        handleError(error);
      }
    }
  };

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <div className="fixed inset-0 bg-black/50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center bg-black/15 overflow-y-auto">
            <Dialog.Panel className="max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle transition-all">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Suppression du quiz
              </Dialog.Title>
              <div className="my-5">
                <p className="text-sm text-gray-500">
                  Êtes-vous sur de vouloir supprimer le questionnaire "
                  <span className="font-bold">{quiz.name}</span>" ?
                </p>
              </div>

              <div className="mt-4 flex flex-col items-center">
                <button
                  onClick={submitDelete}
                  className="w-1/3 mb-2 flex justify-center rounded-md bg-red hover:bg-red/80 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  Supprimer
                </button>
                <button
                  onClick={closeModal}
                  className="w-1/3 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-3 py-1.5 text-sm font-semibold text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                >
                  Annuler
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default QuizDeleteModalComponent;
