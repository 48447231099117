import ScatterplotComponent from "components/scatterplots/ScatterplotComponent";
import React from "react";

function ScatterplotPage() {
  return (
    <div className="w-full">
      <h1 className="p-6 text-2xl font-semibold flex-1">Matrice du projet</h1>
      <ScatterplotComponent />
    </div>
  );
}
export default ScatterplotPage;
