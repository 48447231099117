import React, {RefObject, useEffect, useRef, useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {Question} from "interfaces/Question";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Quiz} from "interfaces/Quiz";
import {createAnswer} from "services/answerService";
import AnswerCreateForm from "form-models/AnswerCreateForm";
import {getQuizByProject} from "services/quizService";
import {ChevronUpIcon} from "@heroicons/react/24/solid";
import {Disclosure} from "@headlessui/react";
import {handleError, ValidationError} from "utils/handleError";
import {toast} from "react-toastify";
import {Loading} from "utils/Loading";
import { postByUrlGetResponse } from "../../services/services";
import ReCAPTCHA from "react-google-recaptcha";
import {isAuthenticated} from "services/authService";
import Checkbox from "components/layout/form/Checkbox";
import ErrorForm from "components/layout/form/ErrorForm";
import Label from "components/layout/form/Label";


function AnswerComponent() {
  const {projectId} = useParams();
  const [quiz, setQuiz] = useState<Quiz>();
  const [errValidation, setErrValidation] = useState<ValidationError | null>(
      null
  );
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  // Fonction pour créer dynamiquement le schéma des réponses
  const answerCreateSchema = (questions: Question[]) => {
    const answerSchema: Record<string, yup.NumberSchema<number>> = {};
    questions.forEach((question) => {
      answerSchema[question.id] = yup
          .number()
          .required(`Réponse à la question "${question.name}" requise`);
    });
    return yup.object().shape(answerSchema);
  };

  // Définir le schéma de validation avec Yup
  const schema = yup.object().shape({
    lastname: yup.string().required("Un nom de famille est requis"),
    firstname: yup.string().required("Un prénom est requis"),
    email: yup.string().required("Un email est requis"),
    position: yup.string().required("Un poste/rôle est requis"),
    answers: yup.lazy(() => answerCreateSchema(quiz?.questions || [])),
    cgu_accepted: yup.bool().oneOf([true], "Vous devez accepter les CGU"),
    communication_accepted: yup.bool().optional(),  
  });

  const [agreement, setAgreement] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreement(event.target.checked);
  }

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(schema),
  });

  // On récupère le Quiz qui appartient au projet
  useEffect(() => {
    // Fonction asynchrone pour effectuer la requête API
    const fetchData = async () => {
      // Vérifier si projectId existe
      if (projectId) {
        try {
          const response = await getQuizByProject(projectId);
          setQuiz(response);
        } catch (error) {
          handleError(error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    // Appeler la fonction fetchData
    fetchData();
  }, [projectId]);

  const site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const [valid_token, setValidToken] = useState<string[] | undefined>([]);
  const captchaRef = useRef<YourCaptchaType | null>(null);
  const authenticated = isAuthenticated();

  // Si le quiz n'est pas défini, n'affiche rien
  if (!quiz && !isLoading) {
    return <div>Ce questionnaire n'est plus disponible</div>;
  }

  // Regroupe les questions du quiz en fonction de leur catégorie.
  // Crée un objet où les clés sont les noms de catégorie et les valeurs sont des arrays de questions.
  // Chaque question est ajoutée à l'array correspondant à sa catégorie.
  const categorizedQuestions = quiz?.questions?.reduce(
      (accumulator, question) => {
        const category = question.category.name;

        // Utilise l'opérateur spread pour créer une nouvelle référence d'array,
        // en ajoutant la nouvelle question à l'array existant pour la catégorie.
        accumulator[category] = [...(accumulator[category] || []), question];
        return accumulator;
      },
      {} as Record<string, Question[]>
  );

  interface YourCaptchaType {
    getValue(): string;

    reset(): void;
  }


  const verifyToken = async (token: string) => {
    try {
      return await postByUrlGetResponse('api/user/verifyToken', {Secret_Key: site_key , reCAPTCHA_TOKEN: token});
    } catch (error) {
      const errorMessages = handleError(error);
      setErrValidation(errorMessages);
    }
  };

  const onSubmit: SubmitHandler<AnswerCreateForm> = async (data) => {
    if (projectId) {
      try {
        if (authenticated) {
          await createAnswer(data, projectId);
          toast.success("Votre réponse est bien enregistrée");
          navigate(`/project/${projectId}/scatterplot`);
          localStorage.setItem("answerEmail", data.email);
        } else {
          if (captchaRef.current) {
            let token = captchaRef.current.getValue();
            captchaRef.current.reset();
            if (token) {
              const valid_token = await verifyToken(token);
              if (valid_token) {
                setValidToken(valid_token);
                if (valid_token.message === 'OK') {
                  await createAnswer(data, projectId);
                  toast.success("Votre réponse est bien enregistrée");
                  navigate(`/project/${projectId}/scatterplot`);
                  localStorage.setItem("answerEmail", data.email);
                } else {
                  toast.error("Une erreur de validation est survenue.");
                }
              }
            } else {
              toast.error("Veuillez accepter le captcha pour continuer.");
            }
          }
        }
      } catch (error) {
        const errorMessages = handleError(error);
        setErrValidation(errorMessages);
      }
    }
  };

  return (
      <div className="w-full">
        {isLoading ? (
            <Loading/>
        ) : (
            <div className="m-4">
              <form className="space-y-6 w-full" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label
                      htmlFor="lastname"
                      className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Nom de famille <span className="text-red">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                        {...register("lastname")}
                        id="lastname"
                        type="text"
                        className="block w-1/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6"
                    />
                    <p className="text-red">{errors.lastname?.message}</p>
                    {errValidation?.errors?.lastname && (
                        <p className="text-red">{errValidation.errors.lastname[0]}</p>
                    )}
                  </div>
                </div>
                <div>
                  <label
                      htmlFor="firstname"
                      className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Prénom <span className="text-red">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                        {...register("firstname")}
                        id="firstname"
                        type="text"
                        className="block w-1/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6"
                    />
                    <p className="text-red">{errors.firstname?.message}</p>
                    {errValidation?.errors?.firstname && (
                        <p className="text-red">
                          {errValidation.errors.firstname[0]}
                        </p>
                    )}
                  </div>
                </div>
                <div>
                  <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Adresse mail <span className="text-red">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                        {...register("email")}
                        id="email"
                        type="email"
                        className="block w-1/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6"
                    />
                    <p className="text-red">{errors.email?.message}</p>
                    {errValidation?.errors?.email && (
                        <p className="text-red">{errValidation.errors.email[0]}</p>
                    )}
                  </div>
                </div>
                <div>
                  <label
                      htmlFor="position"
                      className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Poste ou rôle dans le projet <span className="text-red">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                        {...register("position")}
                        id="position"
                        type="text"
                        className="block w-1/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6"
                    />
                    <p className="text-red">{errors.position?.message}</p>
                    {errValidation?.errors?.position && (
                        <p className="text-red">{errValidation.errors.position[0]}</p>
                    )}
                  </div>
                </div>
                <div>
                  <div>
                    <h2 className="text-lg font-bold">{quiz?.name} :</h2>
                    <div className="mx-auto w-full rounded-2xl bg-white p-2">
                      {Object.entries(categorizedQuestions || {}).map(
                          ([category, questions]) => (
                              <Disclosure defaultOpen key={category}>
                                {({open}) => (
                                    <>
                                      <Disclosure.Button
                                          className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-lg font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75 my-2">
                              <span>
                                <span className="underline">{category}</span> :{" "}
                                {
                                  quiz?.questions.find(
                                      (q) => q.category.name === category
                                  )?.category.description
                                }
                              </span>
                                        <ChevronUpIcon
                                            className={`${
                                                open ? "transform rotate-180" : ""
                                            } w-5 h-5 text-purple-500`}
                                        />
                                      </Disclosure.Button>
                                      <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                                        {questions.map((question) => (
                                            <fieldset
                                                key={question.id}
                                                className="mb-10 border-2 border-gray-300"
                                            >
                                              <legend className="p-2 font-bold">
                                                {question.name} :
                                              </legend>
                                              <div className="flex justify-between mx-6">
                                                <div className="flex w-1/5">
                                                  <div>{question.min_label}</div>
                                                </div>
                                                <div className="flex flex-grow">
                                                  {[1, 2, 3, 4, 5].map((value) => (
                                                      <div
                                                          key={value}
                                                          className="flex flex-col items-center flex-grow"
                                                      >
                                                        <input
                                                            type="radio"
                                                            id={`question${question.id}`}
                                                            {...register(
                                                                `answers.${question.id}`
                                                            )}
                                                            value={value}
                                                            className="mr-2"
                                                        />
                                                        <label
                                                            htmlFor={`question${question.name}`}
                                                        >
                                                          {value}
                                                        </label>
                                                      </div>
                                                  ))}
                                                </div>
                                                <div className="flex w-1/5 justify-end">
                                                  <div>{question.max_label}</div>
                                                </div>
                                              </div>
                                              <div className="mx-6 mt-2">
                                                <p className="text-red">
                                                  {errors.answers?.[question.id]?.message}
                                                </p>
                                              </div>
                                            </fieldset>
                                        ))}
                                      </Disclosure.Panel>
                                    </>
                                )}
                              </Disclosure>
                          )
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex items-baseline">
                    <Checkbox<AnswerCreateForm>
                      registerProps={register("cgu_accepted")}
                      name={"cgu_accepted"}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="cgu_accepted"
                      className="text-sm font-medium leading-6 text-gray-900"
                    >
                      &nbsp;Je reconnais avoir pris connaissance des{" "}
                      <Link
                        to="/cgu"
                        style={{ color: "blue", textDecoration: "underline" }}
                        target="_blank"
                      >
                        CGU
                      </Link>{" "}
                      et de la{" "}
                      <Link
                        to="/confidentality"
                        style={{ color: "blue", textDecoration: "underline" }}
                        target="_blank"
                      >
                        Politique de confidentialité
                      </Link>{" "}
                    </label>
                  </div>
                  <ErrorForm
                    name={"cgu_accepted"}
                    errors={errors}
                    errValidation={errValidation}
                  />
                </div>

                <div className="flex items-baseline">
                  <Checkbox<AnswerCreateForm>
                    registerProps={register("communication_accepted")}
                    name={"communication_accepted"}
                  />
                  <ErrorForm
                    name={"communication_accepted"}
                    errors={errors}
                    errValidation={errValidation}
                  />
                  <Label
                    label={
                      "Je ne souhaite pas recevoir de communications concernant les offres et produits Converteo"
                    }
                    required={false}
                  />
                </div>

                {site_key && !authenticated && (
                    <ReCAPTCHA
                        className="recaptcha"
                        sitekey={site_key}
                        ref={captchaRef as RefObject<ReCAPTCHA>}
                    />
                )}

                <div>
                  <button
                      disabled={!agreement}
                      type="submit"
                      className={`flex justify-center rounded-md ${!agreement ? 'bg-primary-grey' : 'bg-primary-blue'} px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  >
                    Valider
                  </button>
                </div>
              </form>
            </div>
        )}
      </div>
  );
}

export default AnswerComponent;
