import ProjectFormComponent from "components/projects/ProjectFormComponent";
import React from "react";

function ProjectEditPage() {
  return (
    <div className="w-full">
      <h1 className="p-6 text-2xl font-semibold flex-1">Editer un projet</h1>
      <ProjectFormComponent />
    </div>
  );
}
export default ProjectEditPage;
