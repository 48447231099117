import React from "react";
import LoginFormComponent from "components/auth/LoginFormComponent";
import { Link } from "react-router-dom";

function LoginPage() {
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-20 w-auto"
          src="\Logos_RVB_Converteo_WEB.jpg"
          alt="Logo Converteo"
        />
        <h2 className="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
          Connexion
        </h2>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-sm my-10">
        <LoginFormComponent isModal={false}/>
        <p className="mt-10 text-center text-sm text-gray-500">
            Pas encore de compte ?
            <span className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              <Link to="/signup"> S'inscrire</Link>
            </span>
          </p>
      </div>
    </div>
  );
}
export default LoginPage;
