import * as React from "react";
import { useForm } from "react-hook-form";
import { Category } from "interfaces/Category";
import { getAllCategories } from "services/categoryService";
import { useNavigate, useParams } from "react-router-dom";
import { getQuizById, updateQuiz } from "services/quizService";
import { useEffect, useState } from "react";
import { Quiz } from "interfaces/Quiz";
import QuizEditForm from "form-models/QuizEditForm";
import { toast } from "react-toastify";
import { ValidationError, handleError } from "utils/handleError";
import { Loading } from "utils/Loading";
import InputQuestionComponent from "../layout/form/InputQuestionComponent";
import SelectQuestionComponent from "../layout/form/SelectQuestionComponent";
import InputComponent from "../layout/form/InputComponent";
import QuizCreateForm from "../../form-models/QuizCreateForm";

export default function QuizEditComponent() {
  // Utilisez le hook useParams pour récupérer les paramètres de l'URL
  const { quizId } = useParams();
  const [quiz, setQuiz] = useState<Quiz>();
  const [categories, setCategories] = useState<Category[]>([]);
  const [errValidation, setErrValidation] = useState<ValidationError | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<QuizEditForm>({});

  const navigate = useNavigate();

  useEffect(() => {
    // Fonction asynchrone pour effectuer la requête API
    const fetchData = async () => {
      // Vérifier si quizId existe
      if (quizId) {
        try {
          const response = await getQuizById(quizId);
          const newQuiz = response;
          setQuiz(newQuiz);
          setValue("name", response.name);
        } catch (error) {

          handleError(error);
        } finally {
          setIsLoading(false);
        }
      } else {
        toast.error("Une erreur est survenue");
        return false;
      }
    };

    // Appeler la fonction fetchData
    fetchData();
  }, [quizId, setValue]);

  useEffect(() => {
    // Fonction asynchrone pour effectuer la requête API
    const fetchData = async () => {
      try {
        const response = await getAllCategories();
        // Définir la valeur initiale du champ de sélection
        if (quiz && quiz.questions) {
          quiz.questions.forEach((question, index) => {
            setValue(`questions.${index}.category_id`, question.category.id);
          });
        }
        // Mettre à jour le state avec les données de la réponse
        setCategories(response);
      } catch (error) {
        handleError(error);
      }
    };
    fetchData();
  }, [quiz, setValue]);

  const onSubmit = async (data: QuizEditForm) => {
    try {
      if (quizId) {
        // Si la validation réussit, procédez à la mise à jour du quiz
        await updateQuiz(data, quizId);
        navigate("/quiz");
      }
    } catch (error) {
      const errorMessages = handleError(error);
      setErrValidation(errorMessages);
    }
  };

  return (
    <div className="m-4">
      {quiz?.is_answered && (
          <p
              className="flex justify-center rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Le formulaire contenant des réponses associé à un projet, vous ne pouvez pas le modifier
          </p>
      )}
      <form onSubmit={handleSubmit(onSubmit)} method="PUT">

        <InputComponent<QuizCreateForm> label={"Titre du questionnaire"}
                                        required={true}
                                        registerProps={register("name", {
                                          required: "Ce champ est requis"
                                        })}
                                        name={"name"}
                                        errors={errors}
                                        errValidation={errValidation}
                                        isDisabled={quiz?.is_answered ? true : false}
        />

        {isLoading ? (
          <Loading />
        ) : (
          <div>
            {quiz?.questions.map((question, index) => (
              <div key={question.id} className="mb-8">
                <h2 className="underline">Question {index + 1} :</h2>
                <section
                  key={question.id}
                  className="space-x-6 flex flex-row mb-2"
                >
                  <InputQuestionComponent label={"Question"}
                                          htmlFor={`questions[${index}].name`}
                                          required={true}
                                          index={index}
                                          registerProps={register(`questions.${index}.name` as const, {
                                            required: "Ce champ est requis.",
                                          })}
                                          name={`questions.${index}.name`}
                                          placeHolder={"name"}
                                          defaultValue={question.name}
                                          errors={errors}
                                          isDisabled={quiz?.is_answered ? true : false}

                  />

                  <InputQuestionComponent label={"Label notation mini"}
                                          htmlFor={`questions[${index}].min_label`}
                                          required={true}
                                          index={index}
                                          registerProps={register(`questions.${index}.min_label` as const, {
                                            required: "Ce champ est requis.",
                                          })}
                                          name={`questions.${index}.min_label`}
                                          placeHolder={"min_label"}
                                          defaultValue={question?.min_label ?? ""}
                                          errors={errors}
                                          isDisabled={quiz?.is_answered ? true : false}
                  />

                  <InputQuestionComponent label={"Label notation maxi"}
                                          htmlFor={`questions[${index}].max_label`}
                                          required={true}
                                          index={index}
                                          registerProps={register(`questions.${index}.max_label` as const, {
                                            required: "Ce champ est requis.",
                                          })}
                                          name={`questions.${index}.max_label`}
                                          placeHolder={"max_label"}
                                          defaultValue={question?.max_label ?? ""}
                                          errors={errors}
                                          isDisabled={quiz?.is_answered ? true : false}

                  />

                  <SelectQuestionComponent label={'Catégorie de la question'}
                                           required={true}
                                           registerProps={register(`questions.${index}.category_id` as const, {
                                             required: "Vous devez sélectionner une catégorie",
                                           })}
                                           name={'Sélectionnez une catégorie'}
                                           index={index}
                                           selectMap={categories}
                                           htmlFor={`questions[${index}].category_id`}
                                           errors={errors}
                                           defaultValue={question?.category.id ?? ""}
                                           isDisabled={quiz?.is_answered ? true : false}
                  />

                </section>
              </div>
            ))}
          </div>
        )}

        {!quiz?.is_answered && (
          <button
            type="submit"
            className="flex justify-center rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Valider
          </button>
        )}
      </form>
    </div>
  );
}
