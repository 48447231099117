import * as React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { Category } from "interfaces/Category";
import { getAllCategories } from "services/categoryService";
import { useNavigate } from "react-router-dom";
import { createQuiz } from "services/quizService";
import QuizCreateForm from "form-models/QuizCreateForm";
import { useEffect, useState } from "react";
import { ValidationError, handleError } from "utils/handleError";
import InputComponent from "../layout/form/InputComponent";
import InputQuestionComponent from "../layout/form/InputQuestionComponent";
import SelectQuestionComponent from "../layout/form/SelectQuestionComponent";

export default function QuizCreateComponent() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [errValidation, setErrValidation] = useState<ValidationError | null>(
    null
  );
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<QuizCreateForm>({
    defaultValues: {
      questions: [{ name: "", min_label: "", max_label: "", category_id: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "questions",
    control,
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Fonction asynchrone pour effectuer la requête API
    const fetchData = async () => {
      try {
        const response = await getAllCategories();

        // Mettre à jour le state avec les données de la réponse
        setCategories(response);
      } catch (error) {
        handleError(error);
      }
    };
    fetchData();
  }, []);

  const onSubmit = async (data: QuizCreateForm) => {
    try {
      // Si la validation réussit, procédez à la création du quiz
      await createQuiz(data);
      navigate("/quiz");
    } catch (error) {
      const errorMessages = handleError(error);
      setErrValidation(errorMessages);
    }
  };

  return (
    <div className="m-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputComponent<QuizCreateForm> label={"Titre du questionnaire"}
                        required={true}
                        registerProps={register("name", {
                          required: "Ce champ est requis"
                        })}
                        name={"name"}
                        errors={errors}
                        errValidation={errValidation}
        />

        {fields.map((field, index) => {
          return (
            <div key={field.id} className="mb-8">
              <h2 className="underline">Question {index + 1} :</h2>
              <section key={field.id} className="space-x-6 flex flex-row mb-2">

                <InputQuestionComponent label={"Question"}
                                        htmlFor={`questions[${index}].name`}
                                        required={true}
                                        index={index}
                                        registerProps={register(`questions.${index}.name` as const, {
                                          required: "Ce champ est requis.",
                                        })}
                                        name={`questions.${index}.name`}
                                        placeHolder={"name"}
                                        defaultValue={field.name}
                                        errors={errors}

                />

                <InputQuestionComponent label={"Label notation mini"}
                                        htmlFor={`questions[${index}].min_label`}
                                        required={true}
                                        index={index}
                                        registerProps={register(`questions.${index}.min_label` as const, {
                                          required: "Ce champ est requis.",
                                        })}
                                        name={`questions.${index}.min_label`}
                                        placeHolder={"min_label"}
                                        defaultValue={field?.min_label ?? ""}
                                        errors={errors}

                />

                <InputQuestionComponent label={"Label notation maxi"}
                                        htmlFor={`questions[${index}].max_label`}
                                        required={true}
                                        index={index}
                                        registerProps={register(`questions.${index}.max_label` as const, {
                                          required: "Ce champ est requis.",
                                        })}
                                        name={`questions.${index}.max_label`}
                                        placeHolder={"max_label"}
                                        defaultValue={field?.max_label ?? ""}
                                        errors={errors}

                />

                <SelectQuestionComponent label={'Catégorie de la question'}
                                         required={true}
                                         registerProps={register(`questions.${index}.category_id` as const, {
                                           required: "Vous devez sélectionner une catégorie",
                                         })}
                                         name={'Sélectionnez une catégorie'}
                                         index={index}
                                         selectMap={categories}
                                         htmlFor={`questions[${index}].category_id`}
                                         errors={errors}
                                         defaultValue={fields[index]?.category_id ?? ""}
                />

              </section>
              <div className="inline-block">
                <button
                  type="button"
                  className="rounded-md bg-red px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={() => remove(index)}
                >
                  Supprimer
                </button>
              </div>
            </div>
          );
        })}
        <div className="inline-block">
          <div
            onClick={() =>
              append({
                name: "",
                min_label: "",
                max_label: "",
                category_id: "",
              })
            }
            className="flex items-center my-4 hover:bg-gray-200 p-1 rounded-lg cursor-pointer w-auto"
          >
            <PlusCircleIcon className="h-6 w-6 mr-2" />
            <p className="inline-block">Ajouter une question</p>
          </div>
        </div>

        <button
          type="submit"
          className="flex justify-center rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Valider
        </button>
      </form>
    </div>
  );
}
