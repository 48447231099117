import React from "react";

import { FieldValues, UseFormRegisterReturn } from "react-hook-form";

interface CheckboxComponent2Props<T extends FieldValues = FieldValues> {
  registerProps: UseFormRegisterReturn;
  name: string;
  isDisabled?: boolean;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function Checkbox<T>({
  registerProps,
  name,
  isDisabled = false,
  onChange
}: CheckboxComponent2Props) {
  let classnameCss =
    "form-checkbox rounded text-primary-cyan focus:ring-primary-cyan";

  return (
    <div className="mt-2 mr-2">
      <input
        {...registerProps}
        id={name}
        name={name}
        type="checkbox"
        className={classnameCss}
        disabled={isDisabled}
        onChange={onChange}
      />
    </div>
  );
}

export default Checkbox;
