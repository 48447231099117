import React from "react";
import QuizEditComponent from "components/quizzes/QuizEditComponent";

function QuizEditPage() {
  return (
    <div className="w-full">
      <h1 className="p-6 text-2xl font-semibold flex-1">
        Editer un questionnaire
      </h1>
      <QuizEditComponent />
    </div>
  );
}
export default QuizEditPage;
