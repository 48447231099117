import React from "react";

import {UseFormRegisterReturn, FieldValues, FieldError, DeepMap} from 'react-hook-form';

interface InputQuestionComponentProps<T extends FieldValues = FieldValues> {
    label: string;
    required: boolean;
    registerProps: UseFormRegisterReturn;
    name: string;
    index: number;
    placeHolder: string;
    htmlFor: string;
    errors: DeepMap<T, FieldError>;
    isDisabled?: boolean;
    defaultValue: string;
}

function InputQuestionComponent<T>({htmlFor, required, label, index, placeHolder, defaultValue, errors,  registerProps, name, isDisabled = false} : InputQuestionComponentProps)
{
    return (
        <div className="flex-grow">
            <label
                htmlFor={htmlFor}
                className="block text-sm font-medium leading-6 text-gray-900"
            >
                {label}
                {required && (<span className="text-red"> *</span>) }
            </label>

            <div className="mt-2">
                <input
                    {...registerProps}
                    placeholder={placeHolder}
                    id={name}
                    name={name}
                    defaultValue={defaultValue}
                    className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6"
                    disabled={isDisabled}
                />
                <p className="text-red">
                    {errors.questions?.[index]?.[placeHolder]?.message}
                </p>
            </div>
        </div>
    );
}

export default InputQuestionComponent;