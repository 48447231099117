import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import Root from "./routes/Root";
import LoginPage from "./pages/login/LoginPage";
import SignUpPage from "./pages/signup/SignUpPage";
import CategoryPage from "pages/categories/CategoryPage";
import CategoryCreatePage from "pages/categories/CategoryCreatePage";
import CategoryEditPage from "pages/categories/CategoryEditPage";
import QuizPage from "pages/quizzes/QuizPage";
import QuizCreatePage from "pages/quizzes/QuizCreatePage";
import QuizEditPage from "pages/quizzes/QuizEditPage";
import ProjectPage from "pages/projects/ProjectPage";
import ProjectCreatePage from "pages/projects/ProjectCreatePage";
import ProjectEditPage from "pages/projects/ProjectEditPage";
import AnswerPageComponent from "pages/answers/AnswerPageComponent";
import ScatterplotPage from "pages/scatterplot/ScatterplotPage";
import ProfilPage from "pages/profil/ProfilPage";
import PrivateRoute from "utils/PrivateRoute";
import { isAuthenticated } from "services/authService";
import {getMe} from "./services/userService";
import {Loading} from "./utils/Loading";
import CGUPage from "pages/cgu/CGUPage";
import ConfidentialityPage from "pages/cgu/ConfidentialityPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
    return (
        <Router>
            <Routes>
                {/* Route for login page */}
                <Route path="/login" element={<LoginPage />} />

                {/* Route for signup page */}
                <Route path="/signup" element={<SignUpPage />} />

                {/* Route for other pages */}
                <Route path="/" element={<RedirectBasedOnAuth />} />
                <Route path="/*" element={<RootWithRoutes />} />

                {/* Route for cgu pages */}
                <Route path="/cgu" element={<CGUPage />} />
                <Route path="/confidentality" element={<ConfidentialityPage />} />

            </Routes>
        </Router>
    );
};

const RedirectBasedOnAuth = () => {
    const [authenticated, setAuthenticated] = useState<boolean | null>(null);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                await getMe();
                setAuthenticated(true);
            } catch (error) {
                setAuthenticated(false);
            }
        };

        checkAuthentication();
    }, []);

    // Render null or a loading indicator while authentication status is being determined
    if (authenticated === null) {
        return  <Loading/>;
    }
    return authenticated ? <Navigate to="/project" /> : <Navigate to="/login" />;
};

const RootWithRoutes = () => {
    const [authenticated, setAuthenticated] = useState<boolean | null>(null);
    const [role, setRole] = useState<string | null>(null);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const authenticated = await getMe();
                setAuthenticated(true);
                setRole(authenticated.roles[0].name);
            } catch (error) {
                setAuthenticated(false);
            }
        };

        checkAuthentication();
    }, []);

    if (authenticated === null) {
        return  <Loading/>;
    }

    return (
        <Root authenticated={authenticated} role={role}>
            <Routes>
                {/* Route for category */}
                <Route path="/category" element={<PrivateRoute authenticated={authenticated} role={role} onlyAdmin={true} element={<CategoryPage/>} />} />
                <Route path="/category/create" element={<PrivateRoute authenticated={authenticated} role={role} onlyAdmin={true} element={<CategoryCreatePage/>} />} />
                <Route path="/category/:categoryId" element={<PrivateRoute authenticated={authenticated} role={role} onlyAdmin={true} element={<CategoryEditPage/>} />} />

                {/* Route for quiz */}
                <Route path="/quiz" element={<PrivateRoute authenticated={authenticated} role={role} onlyAdmin={true} element={<QuizPage/>} />} />
                <Route path="/quiz/create" element={<PrivateRoute authenticated={authenticated} role={role} onlyAdmin={true} element={<QuizCreatePage/>} />} />
                <Route path="/quiz/:quizId" element={<PrivateRoute authenticated={authenticated} role={role} onlyAdmin={true} element={<QuizEditPage/>} />} />

                {/* Route for project */}
                <Route path="/project" element={<PrivateRoute authenticated={authenticated} role={role} onlyAdmin={false} element={<ProjectPage/>} />} />
                <Route path="/project/create" element={<PrivateRoute allowUnauthenticated={true} authenticated={authenticated} role={role} onlyAdmin={false} element={<ProjectCreatePage />} />} />
                <Route path="/project/:projectId" element={<PrivateRoute authenticated={authenticated} role={role} onlyAdmin={false} element={<ProjectEditPage/>} />} />
                <Route path="/project/:projectId/quiz" element={<PrivateRoute authenticated={authenticated}  allowUnauthenticated={true}  role={role} onlyAdmin={false}  element={<AnswerPageComponent/>} />} />
                <Route path="/project/:projectId/scatterplot" element={<PrivateRoute allowUnauthenticated={true} authenticated={authenticated} role={role} onlyAdmin={false}  element={<ScatterplotPage/>} />} />

                {/* Route for profile */}
                <Route path="/profil" element={<PrivateRoute authenticated={authenticated} role={role} onlyAdmin={false}  element={<ProfilPage/>} />} />

                {/* Add more routes as needed */}
            </Routes>
        </Root>
    );
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <App />
        <ToastContainer />
    </React.StrictMode>
);

reportWebVitals();