import React from "react";
import ProjectFormComponent from "components/projects/ProjectFormComponent";

function ProjectCreatePage() {
  return (
    <div className="w-full">
      <h1 className="p-6 text-2xl font-semibold flex-1">Créer un projet</h1>
      <ProjectFormComponent />
    </div>
  );
}
export default ProjectCreatePage;
