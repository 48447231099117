import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LoginFormComponent from "../LoginFormComponent";

interface MyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSwitchToSignUp: () => void;
  onLoginSuccess: () => void;
}

function LoginModalComponent({ isOpen, onClose, onSwitchToSignUp, onLoginSuccess }: MyModalProps) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0 bg-black/50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center bg-black/15 overflow-y-auto">
            <Dialog.Panel className="max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle transition-all">
              <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Connexion
              </Dialog.Title>
              <div className="sm:mx-auto sm:w-full sm:max-w-sm my-10">
                <LoginFormComponent isModal={true} onClose={onClose} onLoginSuccess={onLoginSuccess}/>
                <p className="mt-10 text-center text-sm text-gray-500">
                    Pas encore de compte ?
                    <span
                    className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 cursor-pointer"
                    onClick={onSwitchToSignUp}
                  >
                    S'inscrire
                  </span>
                </p>
              </div>            
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default LoginModalComponent;
