import React from "react";
import AnswerComponent from "components/answers/AnswerComponent";
function AnswerPageComponent() {
  return (
    <div className="w-full">
      <h1 className="p-6 text-2xl font-semibold flex-1">
        Répondre au questionnaire
      </h1>
      <AnswerComponent />
    </div>
  );
}
export default AnswerPageComponent;
