import {getByUrl} from "./services";

const statusUrl = "api/type-list";


export const getAllTypes = async () => {
    try {
        return getByUrl(statusUrl);
    } catch (error) {
        throw error;
    }
};
