import { toast } from "react-toastify";

export interface ValidationError {
  errors?: Record<string, string[]>;
}

export const handleError = (error: any): ValidationError => {
  const errorMessages: ValidationError = {};

  // Vérifie si `error` est un objet `Response` ou s'il contient une propriété `response`
  const err = error instanceof Response ? error : error.response;

  if(err) {
    if (err.status === 401) {
      toast.error("Vos identifiants sont invalides");
    } else if (err.status === 404) {
      toast.error("La ressource que vous demandez est introuvable");
    } else if (err.status === 409) {
      toast.error("Un conflit avec l'état actuel du serveur a eu lieu");
    } else if (err.status === 422) {

      const responseData: any = error.customData;
      if (responseData?.errors) {
        errorMessages.errors = {};

        for (const [key, messages] of Object.entries(responseData.errors)) {
          if (Array.isArray(messages)) {
            errorMessages.errors[key] = messages.map((message: string) =>
              message === "validation.unique"
                ? "Cette valeur doit être unique."
                : message
            );
          } else {
            // On affiche le message d'erreur directement
            if (messages === "User already respond to this quiz") {
              toast.error("Cet utilisateur a déjà répondu au quiz");
            } else if (messages === "You must have 2 categories") {
              toast.error("Vous devez avoir 2 catégories.");
            } else {
              toast.error("Une erreur de validation est survenue");
            }
          }
        }

      } else if (responseData?.error) {
        toast.error("Des champs obligatoires ne sont pas renseignés.");
      }
    } else if (err.status === 500) {
      toast.error("Une erreur interne du serveur est survenue");
    } else {
      toast.error("Une erreur est survenue");
    }
  }
  return errorMessages;
};
