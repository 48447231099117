import api from "./api";

export async function getByUrl(url: string) {
  try {

    const response = await api.fetchData(url);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getFullResponseByUrl(url: string) {
  try {
    const response = await api.fetchData(url);
    return response;  // Retourner la réponse complète
  } catch (error) {
    throw error;
  }
}

export async function postByUrl(url: string, data: any) {
  try {
    const response = await api.postData(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postByUrlGetResponse(url: string, data: any) {
  try {
    const response = await api.postData(url, data);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function updateByUrl(url: string, data?: any) {
  try {
    const response = await api.putData(url, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteByUrl(url: string) {
  try {
    return await api.deleteData(url);
  } catch (error) {
    throw error;
  }
}
