import { useEffect, useState } from "react";
import { PencilIcon, TrashIcon, EyeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { Quiz } from "interfaces/Quiz";
import { getAllQuizzesWithPagination } from "services/quizService";
import { Switch } from "@headlessui/react";
import QuizDeleteModalComponent from "./modals/QuizDeleteModalComponent";
import QuizActivateModalComponent from "./modals/QuizActivateModalComponent";
import { handleError } from "utils/handleError";
import { Loading } from "utils/Loading";
import { Pagination } from "interfaces/Pagination";
import PaginationComponent from "components/layout/nav/PaginationComponent";

function QuizTableComponent() {
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);
  const [modalName, setModalName] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState<Pagination>();

  const openModal = (index: number, name: string) => {
    setOpenModalIndex(index);
    setModalName(name);
  };

  const closeModal = () => {
    setOpenModalIndex(null);
    setModalName(null);
  };

  const fetchData = async (page?: number) => {
    try {
      const response = await getAllQuizzesWithPagination(page);
      setQuizzes(response.data);
      setPagination(response.meta);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full">
      <div className="p-1.5">
        <div className="flex justify-end mb-2">
          <button className="rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <Link to="create">Créer un questionnaire</Link>
          </button>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <div className="border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                    Nom du questionnaire
                  </th>
                  <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
                    Actif
                  </th>
                  <th className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {quizzes.map((quiz: Quiz, index: number) => (
                  <tr key={quiz.id}>
                    <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                      {quiz.name}
                    </td>
                    <td>
                      <Switch
                        checked={quiz.is_active}
                        className={`${
                          quiz.is_active
                            ? "bg-primary-blue cursor-default"
                            : "bg-gray-200"
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                        onClick={() =>
                          !quiz.is_active && openModal(index, "activate")
                        }
                      >
                        <span
                          className={`${
                            quiz.is_active ? "translate-x-6" : "translate-x-1"
                          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                        />
                      </Switch>
                      {openModalIndex === index && modalName === "activate" && (
                        <QuizActivateModalComponent
                          closeModal={closeModal}
                          onDeleteModalConfirm={fetchData}
                          quiz={quiz}
                        />
                      )}
                    </td>
                    <td className="px-6 py-4 text-sm whitespace-nowrap flex gap-x-6 items-center justify-center">
                      {!quiz.is_answered && (
                        <div className="has-tooltip">
                          <span className="tooltip rounded-xl shadow-lg p-2 bg-gray-700 text-white -mt-10">
                            Editer
                          </span>
                          <div className="hover:bg-gray-200 p-1 rounded-lg cursor-pointer">
                            <Link to={quiz.id}>
                              <PencilIcon className="h-6 w-6" />
                            </Link>
                          </div>
                        </div>
                      )}

                      {quiz.is_answered ? (
                        <div className="has-tooltip">
                          <span className="tooltip rounded-xl shadow-lg p-2 bg-gray-700 text-white -mt-10">
                            Voir le questionnaire
                          </span>
                          <div className="hover:bg-gray-200 p-1 rounded-lg cursor-pointer">
                            <Link to={quiz.id}>
                              <EyeIcon className="h-6 w-6" />
                            </Link>
                          </div>
                        </div>
                      ) : null}

                      {!quiz.is_answered && (
                        <div className="has-tooltip">
                          <span className="tooltip rounded-xl shadow-lg p-2 bg-gray-700 text-white -mt-10">
                            Supprimer
                          </span>
                          <div className="hover:bg-gray-200 p-1 rounded-lg cursor-pointer">
                            <TrashIcon
                              onClick={() => openModal(index, "delete")}
                              className="h-6 w-6"
                            />
                            {openModalIndex === index &&
                              modalName === "delete" && (
                                <QuizDeleteModalComponent
                                  closeModal={closeModal}
                                  onDeleteModalConfirm={fetchData}
                                  quiz={quiz}
                                />
                              )}
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {pagination && <PaginationComponent pagination={pagination} fetchData={fetchData} />}
      </div>
    </div>
  );
}
export default QuizTableComponent;
