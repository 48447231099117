import { Pagination } from "interfaces/Pagination";
import React from "react";

interface PaginationProps {
    pagination: Pagination;
    fetchData: (page: number) => void;    
  }
  
function PaginationComponent({pagination, fetchData}: PaginationProps) {
  return (
    <div className="flex justify-end items-center mt-4 space-x-4">
        <button
            className={`px-4 py-2 rounded-md transition-colors duration-300 bg-gray-300 text-gray-700 ${
                pagination && pagination.current_page > 1
                ? 'hover:bg-gray-400'
                : 'cursor-not-allowed'
            }`}
            onClick={() =>
                pagination && pagination.current_page > 1 && fetchData(pagination.current_page - 1)
            }
            disabled={pagination && pagination.current_page === 1}
        >
            Précédent
        </button>
        {pagination &&
            Array.from({ length: pagination.last_page }, (_, index) => index + 1).map(
                (page) => (
                    <button
                        key={page}
                        className={`px-4 py-2 rounded-md transition-colors duration-300 ${
                            pagination.current_page === page
                                ? 'bg-primary-blue text-white'
                                : 'bg-gray-300 text-gray-700 hover:bg-gray-400'
                        }`}
                        onClick={() => fetchData(page)}
                    >
                        {page}
                    </button>
                )
            )}
        <button
            className={`px-4 py-2 rounded-md transition-colors duration-300 bg-gray-300 text-gray-700 ${
                pagination && pagination.current_page < pagination.last_page
                    ? 'hover:bg-gray-400'
                    : 'cursor-not-allowed'
            }`}
            onClick={() =>
                pagination &&
                pagination.current_page < pagination.last_page &&
                fetchData(pagination.current_page + 1)
            }
            disabled={pagination && pagination.current_page === pagination.last_page}
        >
            Suivant
        </button>
    </div>

  );
}
export default PaginationComponent;
