import SignUpForm from "form-models/SignUpForm";
import { getByUrl, postByUrl, updateByUrl } from "./services";
import UserForm from "form-models/UserForm";

const userUrl = "api/user";

export const registration = async (userData: SignUpForm) => {
  try {
    const url = `${userUrl}/register`;
    return postByUrl(url, userData);
  } catch (error) {
    throw error;
  }
};

export const getMe = async () => {
  try {
    const url = `${userUrl}/me`;

    return getByUrl(url);
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (userData: UserForm, userId: string) => {
  try {
    const url = `${userUrl}/${userId}`;
    return updateByUrl(url, userData);
  } catch (error) {
    throw error;
  }
};
