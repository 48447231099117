import React from "react";

import {FieldValues, UseFormRegisterReturn} from "react-hook-form";

interface TextAreaComponentProps<T extends FieldValues = FieldValues> {
  registerProps: UseFormRegisterReturn;
  name: string;
  isDisabled?: boolean;
}

function TextArea<T>({
                       registerProps,
                       name,
                       isDisabled = false,
                     }: TextAreaComponentProps) {
  return (
      <div className="mt-2">
        <textarea
            {...registerProps}
            id={name}
            name={name}
            className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block w-1/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6"
            disabled={isDisabled}
        ></textarea>
      </div>
  );
}

export default TextArea;
