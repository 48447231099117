// api.js (votre fichier contenant la configuration Axios)
import Cookies from  'js-cookie';

const API_BASE_URL = process.env.REACT_APP_API_URL;

type HeadersType = {
  [key: string]: string;
};

const headers: HeadersType = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};


const api = {
  fetchData: async (endpoint: string, options = {}) => {
    if ( Cookies.get('accessToken')) {
      headers['Authorization'] = `Bearer ${ Cookies.get('accessToken')}`;
    }
    const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
      ...options,
      headers: headers,
    });

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  },

  postData: async (endpoint: string, data= {}) => {
    if ( Cookies.get('accessToken')) {
      headers['Authorization'] = `Bearer ${ Cookies.get('accessToken')}`;
    }

    const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      let responseData;

      if (response.status === 422) {
        const errorDetails = await response.json();
        responseData = {
          response,
          customData: errorDetails,
        };
        throw responseData;
      }

      responseData = {
        response,
      };

      throw responseData;
    }

    return await response.json();
  },

  putData: async (endpoint: string, data= {}) => {
    if ( Cookies.get('accessToken')) {
      headers['Authorization'] = `Bearer ${ Cookies.get('accessToken')}`;
    }

    const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      let responseData;

      if (response.status === 422) {
        const errorDetails = await response.json();
        responseData = {
          response,
          customData: errorDetails,
        };
        throw responseData;
      }

      responseData = {
        response,
      };

      throw responseData;
    }

    return await response.json();
  },

  deleteData: async (endpoint: string) => {
    if ( Cookies.get('accessToken')) {
      headers['Authorization'] = `Bearer ${ Cookies.get('accessToken')}`;
    }

    const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
      method: 'DELETE',
      headers: headers,
    });

    if (!response.ok) {
      let responseData;

      if (response.status === 422) {
        const errorDetails = await response.json();
        responseData = {
          response,
          customData: errorDetails,
        };
        throw responseData;
      }

      responseData = {
        response,
      };

      throw responseData;
    }

    return await response;
  },
};

export default api;
