import React from "react";
import CategoryTableComponent from "components/categories/CategoryTableComponent";

function CategoryPage() {
  return (
    <div className="w-full">
      <h1 className="p-6 text-2xl font-semibold flex-1">
        Liste des catégories
      </h1>
      <CategoryTableComponent />
    </div>
  );
}
export default CategoryPage;
