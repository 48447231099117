import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  createCategory,
  getCategoryById,
  updateCategory,
} from "services/categoryService";
import { ValidationError, handleError } from "utils/handleError";
import CategoryForm from "form-models/CategoryForm";
import Label from "components/layout/form/Label";
import ErrorForm from "components/layout/form/ErrorForm";
import Input from "components/layout/form/Input";
import TextArea from "components/layout/form/TextArea";
import { Category } from "interfaces/Category";

// Définir le schéma de validation avec Yup
const categoryFormSchema = yup.object().shape({
  name: yup.string().required("Un nom de catégorie est requis"),
  description: yup.string().nullable(),
});

function CategoryFormComponent() {
  const { categoryId } = useParams();
  const [errValidation, setErrValidation] = useState<ValidationError | null>(
    null
  );
  const [category, setCategory] = useState<Category>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CategoryForm>({
    defaultValues: async () => {
      try {
        if (categoryId) {
          const response = await getCategoryById(categoryId);
          setCategory({ ...response });
          return response;
        }
      } catch (error) {
        handleError(error);
      }
    },
    resolver: yupResolver(categoryFormSchema),
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<CategoryForm> = async (data) => {
    try {
      if (category) {
        await updateCategory(data, category.id);
      } else {
        await createCategory(data);
      }
      navigate("/category");
    } catch (error) {
      const errorMessages = handleError(error);
      setErrValidation(errorMessages);
    }
  };

  return (
    <div className="w-full">
      <div className="p-1.5 w-full">
        <div className="m-4">
          <form
            className="space-y-6"
            action="#"
            method={categoryId ? "PUT" : "POST"}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Label required={true} label={"Nom"} />
              <Input<CategoryForm>
                registerProps={register("name")}
                name="name"
              />
              <ErrorForm
                name={"name"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label required={false} label={"Description de la catégorie"} />
              <TextArea<CategoryForm>
                registerProps={register("description")}
                name="description"
              />
              <ErrorForm
                name={"description"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>
            <div>
              <button
                type="submit"
                className="flex justify-center rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Confirmer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CategoryFormComponent;
