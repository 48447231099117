import LoginForm from "form-models/LoginForm";
import { postByUrl } from "./services";
import Cookies from 'js-cookie';

const userUrl = "api/user";

export const login = async (loginData: LoginForm) => {
  try {
    const url = `${userUrl}/login`;
    const response = await postByUrl(url, loginData);
    // Stocker le token dans le localStorage
    if (response.access_token) {
      const httpOnlyFlag = process.env.REACT_APP_API_HTTPONLY === 'true';
      Cookies.set('accessToken', response.access_token, { expires: 700, secure: false, httpOnly: httpOnlyFlag});
    }

    return response;
  } catch (error) {
    throw error;
  }
};


export const logout = () => {
  // Supprimer le token du localStorage
  Cookies.remove('accessToken')
};

export const isAuthenticated = () => {
  const accessToken = Cookies.get("accessToken");

  return Boolean(accessToken); // Renvoie true si le token est présent, sinon false
};
