import React, { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { handleError, ValidationError } from "utils/handleError";
import { getMe, updateUser } from "services/userService";
import { User } from "interfaces/User";
import UserForm from "form-models/UserForm";
import { toast } from "react-toastify";
import Label from "../layout/form/Label";
import ErrorForm from "../layout/form/ErrorForm";
import Input from "../layout/form/Input";

// Définir le schéma de validation avec Yup
const profilFormSchema = yup.object().shape({
  lastname: yup.string().required("Un prénom est requis"),
  firstname: yup.string().required("Un nom est requis"),
  company: yup.string().required("Un nom d'entreprise est requis"),
  position: yup.string().required("Un poste/métier est requis"),
  phone: yup.string().nullable(),
});

function ProfilFormComponent() {
  const [errValidation, setErrValidation] = useState<ValidationError | null>(
    null
  );
  const [user, setUser] = useState<User>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserForm>({
    defaultValues: async () => {
      try {
        const response = await getMe();
        const userDataWithoutRoles = { ...response };
        delete userDataWithoutRoles.roles;

        setUser(userDataWithoutRoles);
        return userDataWithoutRoles;
      } catch (error) {
        handleError(error);
      }
    },
    resolver: yupResolver(profilFormSchema),
  });

  const onSubmit: SubmitHandler<UserForm> = async (data) => {
    try {
      if (user) {
        await updateUser(data, user.id);
      }
      toast.success("Informations éditées");
    } catch (error) {
      const errorMessages = handleError(error);
      setErrValidation(errorMessages);
    }
  };

  return (
    <div className="w-full">
      <div className="p-1.5 w-full">
        <div className="m-4">
          <form
            className="space-y-6"
            action="#"
            method="PUT"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Label required={false} label={"Email"} />
              <input
                type="email"
                value={user?.email || ""}
                disabled={true}
                className="input-class w-1/3 p-2 border border-gray-300 rounded-md bg-gray-200 cursor-not-allowed"
              />
            </div>
            <div>
              <Label required={true} label={"Nom"} />
              <Input<UserForm>
                registerProps={register("lastname")}
                name="lastname"
              />
              <ErrorForm
                name={"lastname"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label required={true} label={"Prénom"} />
              <Input<UserForm>
                registerProps={register("firstname")}
                name="firstname"
              />
              <ErrorForm
                name={"firstname"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label required={true} label={"Société"} />
              <Input<UserForm>
                registerProps={register("company")}
                name="company"
              />
              <ErrorForm
                name={"company"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label required={true} label={"Poste/métier"} />
              <Input<UserForm>
                registerProps={register("position")}
                name="position"
              />
              <ErrorForm
                name={"position"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label required={false} label={"Numéro de téléphone"} />
              <Input<UserForm> registerProps={register("phone")} name="phone" />
              <ErrorForm
                name={"phone"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <button
                type="submit"
                className="flex justify-center rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sauvegarder
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ProfilFormComponent;
