import React from "react";

function ConfidentialityPage() {
  return (
    <div className="w-full">
      <div className="text-center">
        <img
          className="mx-auto h-20 w-auto"
          src="\Logos_RVB_Converteo_WEB.jpg"
          alt="Logo Converteo"
        />
        <h1 className="p-6 text-2xl font-bold flex-1">
          Politique de confidentialité
        </h1>
        <h2 className="p-2 text-xl font-semibold flex-1">
          Plateforme Scorecard
        </h2>
      </div>

      <div className="w-3/4 mx-auto text-justify">
        <section className="mb-10">
          <h3 className="my-2 font-bold">Préambule</h3>
          <p>
            Chez Converteo, la protection de vos données personnelles est une
            priorité.
            <br /> <br />
            Lors de votre utilisation de la Plateforme (ci-après le «
            <b> Plateforme</b> »), nous sommes amenés à recueillir des données à
            caractère personnel vous concernant.
            <br /> <br />
            Le but de cette politique est de vous informer sur les modalités
            selon lesquelles nous traitons ces données en conformité avec le
            Règlement (UE) 2016/679 du 27 avril 2016 relatif à la protection des
            personnes physiques à l'égard du traitement des données à caractère
            personnel et à la libre circulation de ces données (ci-après le «
            <b> RGPD</b> »).
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">
            1. Qui est le responsable de traitement ?
          </h3>
          <p>
            La société <b>CONVERTEO</b>, société par actions simplifiée dont le
            siège social est situé au 117-119 quai de Valmy 75010 PARIS,
            immatriculée au Registre du Commerce et des Sociétés de Paris sous
            le numéro 499 582 948, ci-après dénommé « <b>Converteo</b> ».
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">
            2. Quelles sont les données personnelles collectées ?
          </h3>
          <p>
            Dans le cadre de l'utilisation de notre Plateforme, Converteo est
            amené à collecter les données personnelles suivantes :
          </p>
          <br />
          <ul className="list-disc list-inside ml-5">
            <li>Nom</li>
            <li>Prénom</li>
            <li>Adresse e-mail</li>
            <li>Société</li>
            <li>Poste occupé</li>
            <li>Numéro de téléphone</li>
          </ul>
          <br />
          <p>
            Ces données sont nécessaires pour permettre la connexion des
            Utilisateurs à la Plateforme, l'administration de leur compte et
            l'utilisation de la Plateforme tel que décrit au sein des CGU.
            <br /> <br />
            La Plateforme a vocation à être utilisée uniquement pour des
            finalités professionnelles car son objet est de permettre aux
            Utilisateurs de mesurer l'importance de la dimension de gestion du
            changement, dans le cadre du (ou des) projet(s) qu'ils envisagent de
            lancer. Cet outil est conçu pour aider à la prise de décisions
            internes.
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">
            3. Pour quelle finalité et sur quelle base légale sont traitées les
            données ?
          </h3>
          <p>
            Les données personnelles collectées sont utilisées dans les
            finalités suivantes :
          </p>
          <table className="border-collapse border border-black text-left">
            <thead>
              <tr>
                <th className="border border-black">Finalités</th>
                <th className="border border-black">Catégorie de données</th>
                <th className="border border-black">Base légale</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-black">
                  Création et gestion des comptes, authentification et sécurité.
                </td>
                <td className="border border-black">
                  Données d'identification
                </td>
                <td className="border border-black">
                  Obligation contractuelle et pré-contractuelle
                </td>
              </tr>
              <tr>
                <td className="border border-black">
                  Prospection commerciale : Envoi de communication et d'offres
                  commerciales relatives aux services et produits de Converteo.
                </td>
                <td className="border border-black">
                  Données d’identification et données professionnelles
                </td>
                <td className="border border-black">
                  Intérêt légitime de Converteo à promouvoir ses services et
                  produits
                </td>
              </tr>
              <tr>
                <td className="border border-black">
                  Amélioration du service : Analyse de l'utilisation de la
                  plateforme pour améliorer nos services et offrir une meilleure
                  expérience utilisateur.
                </td>
                <td className="border border-black">
                  Données d’identification
                </td>
                <td className="border border-black">
                  Intérêt légitime de Converteo d’améliorer la plateforme
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">
            4. Pendant combien de temps les données sont-elles conservées ?
          </h3>
          <p>
            Les données personnelles des Utilisateurs sont conservées durant une
            période de quatre (4) mois à compter de la dernière connexion à la
            plateforme. Passé ce délai, les données seront supprimées de nos
            systèmes.
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">
            5. Comment les données sont-elles protégées ?
          </h3>
          <p>
            Converteo met en œuvre des mesures techniques et organisationnelles
            appropriées pour protéger les données personnelles des Utilisateurs
            contre toute perte, destruction, altération, accès non autorisé ou
            divulgation. Ces mesures incluent, entre autres, le chiffrement des
            données et des protocoles de sécurité stricts pour l'accès à nos
            systèmes.
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">
            6. Qui sont les destinataires des données ?
          </h3>
          <p>
            Par principe, Converteo s'engage à ne pas partager les données
            personnelles des Utilisateurs avec des tiers sans leur consentement
            préalable.
            <br /> <br />
            Dans le cadre des traitements susvisées, auront accès à vos données
            à caractère personnel :
          </p>
          <ol className="list-decimal list-inside ml-5">
            <li>Le personnel de notre société ;</li>
            <li>
              Nos sous-traitants : prestataires d’hébergement et d’emailing ;
            </li>
            <li>
              Le cas échéant : les organismes publics et privés, exclusivement
              pour répondre à nos obligations légales ou pour protéger les
              intérêts de Converteo.
            </li>
          </ol>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">
            7. Où les données sont-elles transférées ?
          </h3>
          <p>
            Vos données sont conservées et stockées pendant toute la durée des
            traitements sur les serveurs de la société Google LLC situés en
            Union européenne. <br /> <br />
            Dans le cadre des outils que nous utilisons (voir article sur les
            destinataires concernant nos sous-traitants), vos données sont
            susceptibles de faire l'objet de transferts hors de l'Union
            européenne. Le transfert de vos données dans ce cadre est sécurisé
            au moyen des outils suivants :
          </p>
          <ul className="list-disc list-inside ml-5">
            <li>
              soit les données sont transférées dans un pays ayant fait l'objet
              d'une décision d'adéquation de la Commission européenne,
              conformément à l'article 45 du RGPD : dans ce cas, ce pays assure
              un niveau de protection jugé comme suffisant et adéquat aux
              dispositions du RGPD;
            </li>
            <li>
              soit les données sont transférées dans un pays dont le niveau de
              protection des données n'a pas été reconnu comme adéquat au RGPD :
              dans ce cas ces transferts sont fondés sur des garanties
              appropriées indiquées à l'article 46 du RGPD, adaptées à chaque
              prestataire, notamment de façon non exhaustive la conclusion de
              clauses contractuelles types approuvées par la Commission
              Européenne, l'application de règles d'entreprises contraignantes
              ou en vertu d'un mécanisme de certification approuvé.
            </li>
            <li>
              soit les données sont transférées sur le fondement de l’une des
              garanties appropriées décrites au Chapitre V du RGPD.
            </li>
          </ul>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">8. Quels sont vos droits ?</h3>
          <p>
            Conformément à la législation en vigueur, les Utilisateurs disposent
            des droits suivants concernant leurs données personnelles :
          </p>
          <ul className="list-disc list-inside ml-5">
            <li>
              Droit d'accès : Obtenir la confirmation que des données
              personnelles les concernant sont traitées et, le cas échéant, y
              accéder.
            </li>
            <li>
              Droit de rectification : Demander la correction des données
              personnelles inexactes ou incomplètes.
            </li>
            <li>
              Droit à l'effacement : Demander la suppression de leurs données
              personnelles, sous réserve des obligations légales de
              conservation.
            </li>
            <li>
              Droit à la limitation du traitement : Demander la limitation du
              traitement de leurs données personnelles dans certains cas.
            </li>
            <li>
              Droit d'opposition : S'opposer au traitement de leurs données
              personnelles à des fins de prospection commerciale.
            </li>
          </ul>
          <p>
            Si vous souhaitez exercer l’un de ces droits, et à condition de
            justifier de votre identité, vous pouvez écrire à Converteo, 117
            Quai de Valmy, 75010, Paris ou envoyer un message à l’adresse
            dpo@converteo.com.
            <br /> <br />
            En cas de réclamations liées au traitement de vos données
            personnelles, vous pouvez vous adresser directement DPO dont les
            coordonnées sont précisées ci-dessus. En cas de désaccord persistant
            concernant vos données, vous avez la possibilité de saisir la CNIL
            (3 place de Fontenoy 75007 Paris,
            https://www.cnil.fr/fr/vous-souhaitez-contacter-la-cnil - 01 53 73
            22 22).
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">
            9. Modifications de la Politique de Confidentialité
          </h3>
          <p>
            Converteo se réserve le droit de modifier la présente Politique de
            Confidentialité à tout moment. Les Utilisateurs seront informés de
            toute modification par le biais de la plateforme ou par tout autre
            moyen de communication approprié. L'utilisation continue de la
            plateforme après la notification des modifications vaut acceptation
            de la nouvelle Politique de Confidentialité.
            <br /> <br />
            En utilisant notre plateforme, vous reconnaissez avoir pris
            connaissance de cette Politique de Confidentialité et vous acceptez
            les termes et conditions qui y sont décrits.
          </p>
        </section>

        <section className="mb-10">
          <p className="text-right">Version du 22 mai 2024</p>
        </section>
      </div>
    </div>
  );
}
export default ConfidentialityPage;
