import React, { useRef, useState, RefObject } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import SignUpForm from "../../form-models/SignUpForm";
import ReCAPTCHA from "react-google-recaptcha";
import { registration } from "services/userService";
import { ValidationError, handleError } from "utils/handleError";
import { toast } from "react-toastify";
import { postByUrlGetResponse } from "../../services/services";
import Label from "../../components/layout/form/Label";
import Input from "../../components/layout/form/Input";
import ErrorForm from "../../components/layout/form/ErrorForm";
import Checkbox from "components/layout/form/Checkbox";

interface SignUpFormProps {
  isModal: boolean;
  onSignUpSuccess?: () => void; // Fonction de rappel pour le succès d'inscription
}

// Définir le schéma de validation avec Yup
const signUpSchema = yup.object().shape({
  lastname: yup.string().required("Un nom est requis"),
  firstname: yup.string().required("Un prénom est requis"),
  email: yup.string().required("Un email est requis"),
  company: yup.string().required("Une société est requise"),
  position: yup.string().required("Un job est requis"),
  phone: yup.string(),
  password: yup
    .string()
    .required("Un mot de passe est requis")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "Doit contenir au minimum 8 caractères, une miniscule, une majuscule, un chiffre et un caractère spécial (!@#$%^&*)"
    ),
  password_confirmation: yup
    .string()
    .required("Une confirmation de mot de passe est requise.")
    .oneOf([yup.ref("password")], "Les mots de passe ne correspondent pas"),
  cgu_accepted: yup.bool().oneOf([true], "Vous devez accepter les CGU"),
  communication_accepted: yup.bool().optional(),
});

function SignUpFormComponent({ isModal, onSignUpSuccess }: SignUpFormProps) {
  const [errValidation, setErrValidation] = useState<ValidationError | null>(
    null
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpForm>({
    resolver: yupResolver(signUpSchema),
    mode: "onChange",
  });

  interface YourCaptchaType {
    getValue(): string;
    reset(): void;
  }

  const site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const navigate = useNavigate();
  const [valid_token, setValidToken] = useState<string[] | undefined>([]);
  const captchaRef = useRef<YourCaptchaType | null>(null);

  const verifyToken = async (token: string) => {
    try {
      return   await postByUrlGetResponse("api/user/verifyToken", {
        reCAPTCHA_TOKEN: token,
      });
    } catch (error) {
      const errorMessages = handleError(error);
      setErrValidation(errorMessages);
    }
  };

  const onSubmit: SubmitHandler<SignUpForm> = async (data) => {
    try {
      if (captchaRef.current) {
        let token = captchaRef.current.getValue();
        captchaRef.current.reset();

        if (token) {
          const valid_token = await verifyToken(token);
          if (valid_token) {
            setValidToken(valid_token);
            if (valid_token.message === "OK") {
              await registration(data);
              toast.success("Vous pouvez desormais vous connecter");

              if (onSignUpSuccess) {
                onSignUpSuccess(); // Appelle la fonction de succès d'inscription
              }
  
              if (!isModal) {
                navigate("/login"); // Navigation directe si ce n'est pas une modale
              }
            } else {
              toast.error("Une erreur de validation est survenue.");
            }
          }
        } else {
          toast.error("Veuillez accepter le captcha pour continuer.");
        }
      }
    } catch (error) {
      const errorMessages = handleError(error);
      setErrValidation(errorMessages);
    }
  };

  return (
          <form
            className="space-y-6"
            action="#"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Label label={"Nom"} required={true} />
              <Input<SignUpForm>
                registerProps={register("lastname")}
                name={"lastname"}
                className="big"
              />
              <ErrorForm
                name={"lastname"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label label={"Prénom"} required={true} />
              <Input<SignUpForm>
                registerProps={register("firstname")}
                name={"firstname"}
                className="big"
              />
              <ErrorForm
                name={"firstname"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label label={"Société"} required={true} />
              <Input<SignUpForm>
                registerProps={register("company")}
                name={"company"}
                className="big"
              />
              <ErrorForm
                name={"company"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label label={"Poste occupé"} required={true} />
              <Input<SignUpForm>
                registerProps={register("position")}
                name={"position"}
                className="big"
              />
              <ErrorForm
                name={"position"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label label={"Adresse email"} required={true} />
              <Input<SignUpForm>
                registerProps={register("email")}
                name={"email"}
                type="email"
                className="big"
              />
              <ErrorForm
                name={"email"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label label={"Numéro de téléphone"} required={false} />
              <Input<SignUpForm>
                registerProps={register("phone")}
                name={"phone"}
                className="big"
              />
              <ErrorForm
                name={"phone"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label label={"Mot de passe"} required={true} />
              <Input<SignUpForm>
                registerProps={register("password")}
                type="password"
                name={"password"}
                className="big"
              />
              <ErrorForm
                name={"password"}
                errors={errors}
                errValidation={errValidation}
              />
            </div>

            <div>
              <Label label={"Confirmation du mot de passe"} required={true} />
              <Input<SignUpForm>
                registerProps={register("password_confirmation")}
                type="password"
                name={"password_confirmation"}
                className="big"
              />
              <ErrorForm
                name={"password_confirmation"}
                errors={errors}
                errValidation={errValidation}
        />
    </div>

    <div>
        <div className="flex items-baseline">
        <Checkbox<SignUpForm>
            registerProps={register("cgu_accepted")}
            name={"cgu_accepted"}
        />
        <label
            htmlFor="cgu_accepted"
            className="text-sm font-medium leading-6 text-gray-900"
        >
            &nbsp;Je reconnais avoir pris connaissance des{" "}
            <Link
            to="/cgu"
            style={{ color: "blue", textDecoration: "underline" }}
            target="_blank"
            >
            CGU
            </Link>{" "}
            et de la{" "}
            <Link
            to="/confidentality"
            style={{ color: "blue", textDecoration: "underline" }}
            target="_blank"
            >
            Politique de confidentialité
            </Link>{" "}
        </label>
        </div>
        <ErrorForm
        name={"cgu_accepted"}
        errors={errors}
        errValidation={errValidation}
        />
    </div>

    <div className="flex items-baseline">
        <Checkbox<SignUpForm>
        registerProps={register("communication_accepted")}
        name={"communication_accepted"}
        />
        <ErrorForm
        name={"communication_accepted"}
        errors={errors}
        errValidation={errValidation}
        />
        <Label
        label={
            "Je ne souhaite pas recevoir de communications concernant les offres et produits Converteo"
        }
        required={false}
        />
    </div>

    {site_key && (
        <ReCAPTCHA
        className="recaptcha"
        sitekey={site_key}
        ref={captchaRef as RefObject<ReCAPTCHA>}
        />
    )}

    <div>
        <button
        type="submit"
        className="flex w-full justify-center rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
        Confirmer
        </button>
    </div>
    </form>
    );
}

export default SignUpFormComponent;
