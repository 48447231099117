// ProjectActionsComponent.tsx
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  ChartBarSquareIcon,
  PencilIcon,
  DocumentDuplicateIcon,
  ChatBubbleLeftEllipsisIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Project } from "interfaces/Project";
import { toast } from "react-toastify";
import ProjectDeleteModalComponent from "./modals/ProjectDeleteModalComponent";

interface ProjectButtonsProps {
  project: Project;
  fetchData: () => Promise<void>;
}

function ProjectButtonsComponent({ project, fetchData }: ProjectButtonsProps) {
  const [modalName, setModalName] = useState<string | null>(null);

  const openModal = (name: string) => {
    setModalName(name);
  };

  const closeModal = () => {
    setModalName(null);
  };

  const copyUrl = (
    projectId: string,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const urlToCopy = `${window.location.origin}/project/${projectId}/quiz`;

    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        toast.success("URL copié !");
      })
      .catch(() => {
        toast.error("Une erreur est survenue");
      });
    e.stopPropagation();
  };

  return (
    <div className="px-6 py-4 text-sm whitespace-nowrap flex gap-x-6 items-center justify-center">
      {project.quiz.is_active && !project.is_archived && (
        <div className="has-tooltip">
          <span className="tooltip rounded-xl shadow-lg p-2 bg-gray-700 text-white -mt-10">
            Répondre au questionnaire
          </span>
          <div className="hover:bg-gray-200 p-1 rounded-lg cursor-pointer">
            <Link
              to={`${project.id}/quiz`}
              onClick={(e) => e.stopPropagation()}
            >
              <ChatBubbleLeftEllipsisIcon className="h-6 w-6" />
            </Link>
          </div>
        </div>
      )}
      <div className="has-tooltip">
        <span className="tooltip rounded-xl shadow-lg p-2 bg-gray-700 text-white -mt-10">
          Voir le nuage de points
        </span>
        <div className="hover:bg-gray-200 p-1 rounded-lg cursor-pointer">
          <Link
            to={`${project.id}/scatterplot`}
            onClick={(e) => e.stopPropagation()}
          >
            <ChartBarSquareIcon className="h-6 w-6" />
          </Link>
        </div>
      </div>
      {project.quiz.is_active && !project.is_archived && !project.is_answered && (
        <div className="has-tooltip">
          <span className="tooltip rounded-xl shadow-lg p-2 bg-gray-700 text-white -mt-10">
            Editer
          </span>
          <div className="hover:bg-gray-200 p-1 rounded-lg cursor-pointer">
            <Link to={project.id} onClick={(e) => e.stopPropagation()}>
              <PencilIcon className="h-6 w-6" />
            </Link>
          </div>
        </div>
      )}
      {project.quiz.is_active && !project.is_archived && (
        <div className="has-tooltip">
          <span className="tooltip rounded-xl shadow-lg p-2 bg-gray-700 text-white -mt-10">
            Copier l'URL du quiz
          </span>
          <div
            className="hover:bg-gray-200 p-1 rounded-lg cursor-pointer"
            onClick={(e) => copyUrl(project.id, e)}
          >
            <DocumentDuplicateIcon className="h-6 w-6" />
          </div>
        </div>
      )}
      {project.is_answered === 0 ? (
        <div className="has-tooltip">
          <span className="tooltip rounded-xl shadow-lg p-2 bg-gray-700 text-white -mt-10">
            Supprimer
          </span>
          <div className="hover:bg-gray-200 p-1 rounded-lg cursor-pointer">
            <TrashIcon
              onClick={() => openModal("delete")}
              className="h-6 w-6"
            />
            {modalName === "delete" && (
              <ProjectDeleteModalComponent
                closeModal={closeModal}
                project={project}
                fetchData={fetchData}
              />
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ProjectButtonsComponent;
