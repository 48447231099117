import React from "react";
import QuizCreateComponent from "components/quizzes/QuizCreateComponent";

function QuizCreatePage() {
  return (
    <div className="w-full">
      <h1 className="p-6 text-2xl font-semibold flex-1">
        Créer un questionnaire
      </h1>
      <QuizCreateComponent />
    </div>
  );
}
export default QuizCreatePage;
