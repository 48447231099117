import React from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "services/authService";
import MenuProfilComponent from "./MenuProfileComponent";

function HeaderComponent() {
  const authenticated = isAuthenticated();
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (authenticated) {
      navigate("/project");
    }
  };

  return (
    <div className="bg-rose w-full border-y flex items-center justify-between">
      <img
        className="h-auto w-60"
        src="\Logos_RVB_Converteo_WEB.jpg"
        alt="Logo Converteo"
        style={{ cursor: authenticated ? "pointer" : "default" }}
        onClick={handleNavigate}
      />

      {authenticated && <MenuProfilComponent />}
    </div>
  );
}
export default HeaderComponent;
