import AnswerCreateForm from "form-models/AnswerCreateForm";
import { getByUrl, getFullResponseByUrl, postByUrl } from "./services";

const answerUrl = "api/answer";

export const createAnswer = async (
  answerData: AnswerCreateForm,
  projectId: string
) => {
  try {
    const url = `${answerUrl}/${projectId}`;
    return postByUrl(url, answerData);
  } catch (error) {
    throw error;
  }
};

export const getAnswersByProject = async (projectId: string) => {
  try {
    const url = `${answerUrl}?project_id=${projectId}`;
    return getByUrl(url);
  } catch (error) {
    throw error;
  }
};

export const getAnswersWithPaginationByProject = async (projectId: string, page?:number) => {
  try {
    const url = `${answerUrl}?project_id=${projectId}${page !== undefined ? `&page=${page}` : ''}`;
    return getFullResponseByUrl(url);
  } catch (error) {
    throw error;
  }
};
