import React from "react";
import { Link } from "react-router-dom";

function FooterComponent() {
  return (
    <footer className="border-y">
      <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-center">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 mr-4">
          © 2024{" "}
          <a
            href="https://converteo.com/"
            target="_blank"
            className="hover:underline"
            rel="noreferrer"
          >
            Converteo
          </a>
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <Link
              to="/cgu"
              target="_blank"
              className="hover:underline me-4 md:me-6"
            >
              Conditions Générales d'Utilisation
            </Link>
          </li>
          <li>
            <Link
              to="/confidentality"
              target="_blank"
              className="hover:underline me-4 md:me-6"
            >
              Politique de confidentialité
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}
export default FooterComponent;
