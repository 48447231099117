// ProjectTableComponent.tsx
import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import { Project } from "interfaces/Project";
import ProjectArchiveModalComponent from "./modals/ProjectArchiveModalComponent";
import ProjectButtonsComponent from "./ProjectButtonsComponent";

interface ProjectTableProps {
  projects: Project[];
  fetchData: () => Promise<void>;
}

function ProjectTableComponent({ projects, fetchData }: ProjectTableProps) {
  const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);
  const [modalName, setModalName] = useState<string | null>(null);
  const openModal = (index: number, name: string) => {
    setOpenModalIndex(index);
    setModalName(name);
  };

  const closeModal = () => {
    setOpenModalIndex(null);
    setModalName(null);
  };

  return (
    <div className="border rounded-lg">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
              Name
            </th>
            <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
              Description
            </th>
            <th className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase">
              Actif
            </th>
            <th className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {projects.map((project, index: number) => (
            <tr key={project.id}>
              <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                {project.name}
              </td>
              <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                {project.description}
              </td>
              <td>
                <Switch
                  checked={!project.is_archived}
                  className={`${
                    !project.is_archived ? "bg-primary-blue" : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                  onClick={() => openModal(index, "archiveAndUnarchive")}
                >
                  <span
                    className={`${
                      project.is_archived ? "translate-x-6" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
                {openModalIndex === index &&
                  modalName === "archiveAndUnarchive" && (
                    <ProjectArchiveModalComponent
                      closeModal={closeModal}
                      project={project}
                      fetchData={fetchData}
                    />
                  )}
              </td>
              <td className="px-6 py-4 text-sm whitespace-nowrap flex gap-x-6 items-center justify-center">
                <ProjectButtonsComponent
                  project={project}
                  fetchData={fetchData}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProjectTableComponent;
