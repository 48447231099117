import React from "react";

function CGUPage() {
  return (
    <div className="w-full">
      <div className="text-center">
        <img
          className="mx-auto h-20 w-auto"
          src="\Logos_RVB_Converteo_WEB.jpg"
          alt="Logo Converteo"
        />
        <h1 className="p-6 text-2xl font-bold flex-1">
          Conditions Générales d'Utilisation
        </h1>
        <h2 className="p-2 text-xl font-semibold flex-1">
          Plateforme Scorecard
        </h2>
      </div>

      <div className="w-3/4 mx-auto text-justify">
        <section className="mb-10">
          <h3 className="my-2 font-bold">Préambule</h3>
          <p>
            Les présentes Conditions Générales d'Utilisation (CGU) définissent
            les modalités et conditions dans lesquelles les clients (ci-après
            "les <b>Utilisateurs</b>") peuvent utiliser l'outil (ci-après "
            <b>Plateforme</b>") mis à leur disposition par Converteo ci-après "
            <b>Converteo</b>" ou "<b>l'Editeur</b>", leur permettant de créer
            des schémas sur l'état d'avancement de leur politique de changement.
            En accédant à la Plateforme, les Utilisateurs acceptent sans réserve
            les présentes CGU.
            <br /> <br />
            La société <b>CONVERTEO</b>, société par actions simplifiée dont le
            siège social est situé au 117-119 quai de Valmy 75010 PARIS,
            immatriculée au Registre du Commerce et des Sociétés de Paris sous
            le numéro 499 582 948
            <br /> <br />
            Ci-après dénommé « <b>Converteo</b> » ;
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">1. Objet de l'Outil</h3>
          <p>
            La Plateforme fournie par Converteo permet aux Utilisateurs de
            mesurer l'importance de la dimension de gestion du changement, dans
            le cadre du (ou des) projet(s) qu'ils envisagent de lancer. Cet
            outil est conçu pour aider à la prise de décisions internes.
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">2. Accès à la Plateforme</h3>
          <p>
            L'accès à la Plateforme est réservé aux Utilisateurs ayant souscrit
            à ce service auprès de Converteo et sont de deux catégories : les
            administrateurs et les répondants. Chaque administrateur doit
            disposer de ses propres identifiants de connexion, qui sont
            strictement personnels et confidentiels. Les autres Utilisateurs, à
            savoir les répondants, n'ont pas besoin de créer d'identifiants et
            mots de passe pour utiliser la Plateforme. Les Utilisateurs
            s'engagent à ne pas partager leurs identifiants avec des tiers ou le
            lien de la Plateforme vers des tiers non autorisés.
            <br /> <br />
            Chaque Utilisateur s'engage à disposer des droits nécessaires pour
            pouvoir accéder à la Plateforme mise à disposition par
            l'administrateur.
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">3. Utilisation de la Plateforme </h3>
          <p>
            Les Utilisateurs peuvent utiliser l'outil pour répondre à
            différentes questions afin de créer, modifier et analyser des
            schémas liés à l'état d'avancement de leur politique de changement.
            Ils sont responsables de l'exactitude et de la pertinence des
            données qu'ils saisissent dans l'outil.
            <br /> <br />
            Converteo n'est pas responsable de la gestion des Utilisateurs sur
            la Plateforme, il appartient à l'administrateur d'inviter les autres
            Utilisateurs et d'en gérer l'accès, l'exclusion et se porte garant
            d'une utilisation conforme par les Utilisateurs invités. <br />{" "}
            <br />
            L'Utilisateur s'engage à:
          </p>
          <ul className="list-disc list-inside ml-5">
            <li>
              ne pas porter atteinte au déroulement normal des services et de
              ses infrastructures, de quelque manière que ce soit ;
            </li>
            <li>
              Utiliser la Plateforme uniquement à des fins professionnelles, et
              ne pas le détourner à d'autres fins ;
            </li>
            <li>
              ne pas entreprendre d'activités de nature à nuire, contrôler,
              interférer, violer l'intégrité ou la sécurité de l'infrastructure
              informatique de Converteo;
            </li>
            <li>ne pas porter atteinte aux mesures de sécurité en place ;</li>
            <li>
              ne pas s'introduire ou tenter de s'introduire dans le système
              informatique de Converteo ou d'un tiers ;
            </li>
            <li>
              ne pas modifier, copier, assembler, décompiler, ou tenter
              d'utiliser les codes source, algorithmes et logiciels de la
              Plateforme Converteo, à d'autres fins que celles prévues dans les
              présentes CGU, et notamment à des fins de rétro-ingénierie ;
            </li>
            <li>
              ne pas exercer d'activités illégales, frauduleuses ou portant
              atteinte à la sécurité et la vie privée des tiers ou à l'intégrité
              des actifs informatiques et financiers de Converteo ;
            </li>
            <li>
              ne pas copier et/ou détourner à vos propres fins ou à celles de
              tiers le concept, les technologies ou tout autre élément de la
              Plateforme Converteo ;
            </li>
            <li>
              ne pas enfreindre les réglementations relatives à la propriété
              intellectuelle ;
            </li>
            <li>
              ne pas porter atteinte aux droits et intérêts financiers,
              commerciaux, moraux et à l'image de Converteo ;
            </li>
            <li>
              ne pas monnayer, vendre ou concéder tout ou partie de l'accès au
              Profil Converteo, ainsi qu'aux informations qui y sont hébergées
              et/ou partagées.
            </li>
          </ul>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">4. Données Confidentielles</h3>
          <p>
            Les Utilisateurs reconnaissent qu'en utilisant l'outil, ils peuvent
            fournir des données confidentielles de l'entreprise à laquelle ils
            appartiennent et en lien avec le projet objet de l'étude fournie par
            la Plateforme. Converteo met en œuvre tous les moyens à sa
            disposition pour assurer la sécurité des données conformément à sa
            Politique de confidentialité. Toutefois, les Utilisateurs s'assurent
            que les données saisies ne contiennent pas d'informations sensibles.{" "}
            <br /> <br />
            Les Utilisateurs s'engagent à ne pas fournir de données couvertes
            par le secret des affaires et des données personnelles sensibles.
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">5. Limitation de Garantie</h3>
          <p>
            Converteo ne peut garantir que l'outil fournira des résultats
            conformes aux attentes des Utilisateurs. En outre, Converteo ne
            garantit pas la disponibilité continue et ininterrompue de l'outil.
            Les interruptions de service, les erreurs ou les bugs peuvent
            survenir, et Converteo ne pourra être tenu responsable des
            conséquences de telles interruptions.
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">6. Responsabilité de l'Utilisateur</h3>
          <p>
            L'Utilisateur est responsable des risques liés à l'utilisation de
            son identifiant de connexion et de son mot de passe. Le mot de passe
            de l'Utilisateur doit rester secret. En cas de divulgation de mot de
            passe ou d'accès illégitime dû à une faute ou négligence de
            l'Utilisateur, l'Éditeur décline toute responsabilité.
            <br /> <br />
            L'Utilisateur est responsable de la qualité, l'intégrité des
            données, y compris personnelles, fournies lors de l'utilisation de
            la Plateforme. A ce titre, l'Utilisateur s'engage à fournir des
            informations exactes et à jour et à ne pas enfreindre l'ordre
            public, les lois et réglementations en vigueur, concernant notamment
            la liberté d'expression.
            <br /> <br />
            L'Utilisateur s'engage à tenir des propos respectueux des autres et
            de la loi et accepte que ces publications soient modérées ou
            refusées par l'Éditeur, sans obligation de justification.
            <br /> <br />
            L'Editeur se réserve l'exclusion de tout Utilisateur qui ferait un
            usage de la Plateforme contraire aux présentes ou à la
            réglementation en vigueur. Tout usage de la Plateforme par
            l'Utilisateur ayant directement ou indirectement pour conséquence
            des dommages doit faire l'objet d'une indemnisation au profit de
            Converteo.
            <br /> <br />
            L'Utilisateur assume l'entière responsabilité de l'utilisation qu'il
            fait des informations et contenus présents sur le site Votre site.
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">7. Responsabilité de Converteo</h3>
          <p>
            Converteo décline toute responsabilité pour les dommages directs ou
            indirects résultant de l'utilisation ou de l'impossibilité
            d'utiliser l'outil. Les Utilisateurs sont seuls responsables des
            décisions prises sur la base des résultats obtenus via l'outil.
            <br /> <br />
            Converteo ne serait être tenu responsable pour tout évènement
            relevant d'un cas de force majeure tel que définit par le Code
            civil.
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">8. Propriété Intellectuelle</h3>
          <p>
            Tous les droits de propriété intellectuelle relatifs à l'outil et à
            son contenu (textes, images, logiciels, etc.) sont la propriété
            exclusive de Converteo. Les Utilisateurs s'engagent à ne pas
            reproduire, modifier ou distribuer ces contenus sans l'autorisation
            préalable de Converteo.
            <br /> <br />
            En publiant sur la Plateforme, l'Utilisateur cède à la société
            éditrice le droit non exclusif et gratuit de représenter,
            reproduire, adapter, modifier, diffuser et distribuer sa
            publication, directement ou par un tiers autorisé.
            <br /> <br />
            Converteo s'engage toutefois à citer le membre en cas d'utilisation
            de sa publication.
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">9. Modification des CGU</h3>
          <p>
            Converteo se réserve le droit de modifier les présentes CGU à tout
            moment. Les Utilisateurs seront informés de toute modification via
            l'outil ou par tout autre moyen de communication approprié.
            L'utilisation continue de l'outil après notification de
            modifications vaut acceptation des nouvelles CGU.
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">
            10. Droit Applicable et Juridiction
          </h3>
          <p>
            Les présentes CGU sont régies par le droit français. Tout litige
            relatif à leur interprétation ou à leur exécution sera soumis à la
            compétence exclusive des tribunaux dans le ressort du siège de
            Converteo.
          </p>
        </section>

        <section className="mb-10">
          <h3 className="my-2 font-bold">11. Contact</h3>
          <p>
            Pour toute question ou réclamation relative à l'utilisation de
            l'outil, les Utilisateurs peuvent contacter Converteo à l'adresse
            suivante : contact@converteo.com
          </p>
        </section>
      </div>
    </div>
  );
}
export default CGUPage;
