import React, {RefObject, useEffect, useRef, useState} from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {SubmitHandler, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {createProject, getProjectById, updateProject,} from "services/projectService";
import {handleError, ValidationError} from "utils/handleError";
import ProjectForm from "form-models/ProjectForm";
import TextAreaComponent from "../layout/form/TextAreaComponent";
import Input from "../layout/form/Input";
import Label from "../layout/form/Label";
import ErrorForm from "../layout/form/ErrorForm";
import {toast} from "react-toastify";
import {Status} from "../../interfaces/Status";
import {Type} from "../../interfaces/Type";
import {getAllStatus} from "../../services/statusService";
import SelectComponent from "../layout/form/SelectComponent";
import {getAllTypes} from "../../services/typeService";
import  { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from 'date-fns/locale/fr';
import SignUpModalComponent from "components/auth/modals/SignUpModalComponent";
import { isAuthenticated } from "services/authService";
import ReCAPTCHA from "react-google-recaptcha";
import { postByUrlGetResponse } from "services/services";
import LoginModalComponent from "components/auth/modals/LoginModalComponent";
registerLocale('fr', fr);
setDefaultLocale('fr');

// Définir le schéma de validation avec Yup
const projectFormSchema = yup.object().shape({
  name: yup.string().required("Un nom de catégorie requis"),
  description: yup.string().required("Le détail du projet est requis"),
  company_name: yup.string().required("Le nom de la company est requis"),
});

interface YourCaptchaType {
  getValue(): string;
  reset(): void;
}

function ProjectFormComponent() {
  const [status, setStatus] = useState<Status[]>([]);
  const [defaultValue, setDefaultValue] = useState("");
  const [defaultType, setDefaultType] = useState("");
  const [type, setType] = useState<Type[]>([]);
  const [pendingData, setPendingData] = useState<ProjectForm | null>(null);
  const authenticated = isAuthenticated();
  const site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const captchaRef = useRef<YourCaptchaType | null>(null);
  const [valid_token, setValidToken] = useState<string[] | undefined>([]);
  const [openLoginModal, setOpenLoginModal] = useState<boolean>(false); // État pour gérer l'ouverture de la modale de connexion
  const [openSignUpModal, setOpenSignUpModal] = useState<boolean>(false); // État pour gérer l'ouverture de la modale d'inscription

  const handleOpenLoginModal = () => {
    setOpenLoginModal(true); // Ouvre la modale de connexion
    setOpenSignUpModal(false); // Assure que la modale d'inscription est fermée
  };

  const handleOpenSignUpModal = () => {
    setOpenSignUpModal(true); // Ouvre la modale d'inscription
    setOpenLoginModal(false); // Assure que la modale de connexion est fermée
  };

  const handleCloseModals = () => {
    setOpenLoginModal(false); // Ferme la modale de connexion
    setOpenSignUpModal(false); // Ferme la modale d'inscription
  };

  // Utilisez le hook useParams pour récupérer les paramètres de l'URL
  const {projectId} = useParams();
  const [errValidation, setErrValidation] = useState<ValidationError | null>(
      null
  );
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<ProjectForm>({
    defaultValues: async () => {
      if (projectId) {
        try {
          const response = await getProjectById(projectId);

          if(response.status != null) {
            setDefaultValue(response.status.id);
          }
          if(response.type != null) {
            setDefaultType(response.type.id);
          }

          return response;

        } catch (error) {
          handleError(error);
        }
      }
    },
    resolver: yupResolver(projectFormSchema),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllStatus();
        if(response[0]) {
          setStatus(response);
          if(!projectId) {
            setDefaultValue(response[0].id);
          }
        }

        const responseType = await getAllTypes();
        if (responseType) {
          setType(responseType);
        }
      } catch (error) {
        handleError(error);
      }
    };
    fetchData();
  }, []);

  const navigate = useNavigate();

  const verifyToken = async (token: string) => {
    try {
      return await postByUrlGetResponse("api/user/verifyToken", {
        reCAPTCHA_TOKEN: token,
      });
    } catch (error) {
      const errorMessages = handleError(error);
      setErrValidation(errorMessages);
    }
  };

  const submitForm = async (data: ProjectForm) => {
    try {
      if (data.status_type_id === "") {
        setValue("status_type_id", defaultValue);
      }
  
      if (data.type_id === "") {
        setValue("type_id", defaultType);
      }

      const projectData: ProjectForm = {
        name: data.name,
        company_name: data.company_name,
        description: data.description,
        status_type_id: data.status_type_id,
        type_id: data.type_id,
      };

      if (projectId) {
        await updateProject(projectData, projectId);
        toast.success("Le projet a été mis à jour avec succès.");
      } else {
        await createProject(data);
        toast.success("Le projet a été créé avec succès.");
      }
      navigate("/project");
    } catch (error: ValidationError | any) {
      if (error.response.status === 401) {
        setPendingData(data); // Stocker les données du formulaire
        handleOpenLoginModal();
      } else {
        const errorMessages = handleError(error);
        setErrValidation(errorMessages);
      }
    }
  };
  
  const onSubmit: SubmitHandler<ProjectForm> = async (data) => {
    if (authenticated) {
      // Si l'utilisateur est authentifié, on soumet les données directement
      await submitForm(data);
    } else {
      // Si l'utilisateur n'est pas authentifié, on vérifie le token captcha
      if (captchaRef.current) {
        let token = captchaRef.current.getValue();
        captchaRef.current.reset();
  
        if (token) {
          try {
            const valid_token = await verifyToken(token);
            if (valid_token && valid_token.message === "OK") {
              await submitForm(data);
            } else {
              toast.error("Une erreur de validation est survenue.");
            }
          } catch (error) {
            const errorMessages = handleError(error);
            setErrValidation(errorMessages);
          }
        } else {
          toast.error("Veuillez accepter le captcha pour continuer.");
        }
      }
    }
  };

  const handleLoginSuccess = async () => {
    if (pendingData) {
      try {
        if (projectId) {
          await updateProject(pendingData, projectId);
          toast.success("Le projet a été mis à jour avec succès.");
        } else {
          await createProject(pendingData);
          toast.success("Le projet a été créé avec succès.");
        }
        navigate("/project");
      } catch (error: ValidationError | any) {
        const errorMessages = handleError(error);
        setErrValidation(errorMessages);
      }
    }
  }

  return (
      <div className="w-full">
        <div className="m-4">
          <form
              className="space-y-6"
              method={projectId ? "PUT" : "POST"}
              onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Label label={"Nom du projet"} required={true}/>
              <Input<ProjectForm> registerProps={register("name")} name={"name"}/>
              <ErrorForm name={"name"} errors={errors} errValidation={errValidation}/>
            </div>

            <div>
              <Label label={"Nom de l'entreprise"} required={true}/>
              <Input<ProjectForm> registerProps={register("company_name")} name={"company_name"}/>
              <ErrorForm name={"company_name"} errors={errors} errValidation={errValidation}/>
            </div>

            <div>
              <Label label={"Détail du projet Pricing"} required={true}/>
              <TextAreaComponent<ProjectForm> registerProps={register("description")} name={"description"}/>
              <ErrorForm name={"description"} errors={errors} errValidation={errValidation}/>
            </div>

            <div>
              <Label label={"Statut du projet"} required={true}/>
              <SelectComponent name={"status_type_id"} registerProps={register("status_type_id")} selectMap={status} selected={defaultValue} haveEmptyEntry={false} />
              <ErrorForm name={"status_type_id"} errors={errors} errValidation={errValidation}/>
            </div>

            <div>
              <Label label={"Type du projet"} required={true}/>
              <SelectComponent
                name={"type_id"}
                registerProps={register("type_id")}
                selectMap={type}
                selected={defaultType}
                haveEmptyEntry={false}
            />
              <ErrorForm name={"type_id"} errors={errors} errValidation={errValidation}/>
            </div>

            {!authenticated && site_key && (
              <ReCAPTCHA
              className="recaptcha"
              sitekey={site_key}
              ref={captchaRef as RefObject<ReCAPTCHA>}
              />
            )}

            <div>
              <button
                  type="submit"
                  className="flex justify-center rounded-md bg-primary-blue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Valider
              </button>
            </div>
          </form>
        </div>
      {openLoginModal && (
        <LoginModalComponent
          isOpen={openLoginModal}
          onClose={handleCloseModals}
          onSwitchToSignUp={handleOpenSignUpModal} // Gère la bascule vers la modale d'inscription
          onLoginSuccess={handleLoginSuccess}
        />
      )}

      {openSignUpModal && (
        <SignUpModalComponent
          isOpen={openSignUpModal}
          onClose={handleCloseModals}
          onSwitchToLogin={handleOpenLoginModal} // Gère la bascule vers la modale d'inscription
          onLoginSuccess={handleCloseModals}
        />
      )}
      </div>
  );
}

export default ProjectFormComponent;
