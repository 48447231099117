import React from "react";

import {FieldValues} from 'react-hook-form';

interface LabelComponentProps<T extends FieldValues = FieldValues> {
    label: string,
    required: boolean,
}

function Label({required, label}: LabelComponentProps) {
    return (
        <div className="label">
            <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
            >
                {label}
                {required && (<span className="text-red"> *</span>)}
            </label>
        </div>
    );
}

export default Label;