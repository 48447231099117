import React from "react";

import { UseFormRegisterReturn, FieldErrors, FieldValues, FieldError } from 'react-hook-form';
import { ValidationError } from "utils/handleError";

interface InputComponentProps<T extends FieldValues = FieldValues> {
    label: string,
    required: boolean,
    registerProps: UseFormRegisterReturn;
    name: string;
    errors: FieldErrors<T>;
    isDisabled?: boolean;
    errValidation: ValidationError | null;
}

function InputComponent<T>({required, label, registerProps, name, errors, errValidation, isDisabled=false} : InputComponentProps)
{
    const fieldError = errors[name] as FieldError | undefined;
    return (
        <div>
            <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
            >
                {label}
                {required && (<span className="text-red"> *</span>) }
            </label>

            <div className="mt-2">
                <input
                    {...registerProps}
                    id={name}
                    name={name}
                    type="text"
                    className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block w-1/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-cyan sm:text-sm sm:leading-6"
                    disabled={isDisabled}
                />

                {fieldError && <p className="text-red">{fieldError?.message}</p>}
                {errValidation && errValidation.errors && errValidation.errors[name] && (
                    <p className="text-red">{errValidation.errors[name][0]}</p>
                )}
            </div>
        </div>
    );
}

export default InputComponent;