import QuizCreateForm from "form-models/QuizCreateForm";
import QuizEditForm from "form-models/QuizEditForm";
import { deleteByUrl, getByUrl, getFullResponseByUrl, postByUrl, updateByUrl } from "./services";

const quizUrl = "api/quiz";

export const getAllQuizzes = async () => {
  try {
    return getByUrl(quizUrl);
  } catch (error) {
    throw error;
  }
};

export const getAllQuizzesWithPagination = async (page?:number) => {
  try {
    return getFullResponseByUrl(`${quizUrl}${page !== undefined ? `?page=${page}` : ''}`);
  } catch (error) {
    throw error;
  }
};

export const getQuizById = async (quizId: string) => {
  try {
    const url = `${quizUrl}/${quizId}`;
    return getByUrl(url);
  } catch (error) {
    throw error;
  }
};

export const deleteQuiz = async (quizId: string) => {
  try {
    const url = `${quizUrl}/${quizId}`;
    return deleteByUrl(url);
  } catch (error) {
    throw error;
  }
};

export const createQuiz = async (quizData: QuizCreateForm) => {
  try {
    return postByUrl(quizUrl, quizData);
  } catch (error) {
    throw error;
  }
};

export const updateQuiz = async (quizData: QuizEditForm, quizId: string) => {
  try {
    const url = `${quizUrl}/${quizId}`;
    return updateByUrl(url, quizData);
  } catch (error) {
    throw error;
  }
};

export const activateQuiz = async (quizId: string) => {
  try {
    const url = `${quizUrl}/activate/${quizId}`;
    return updateByUrl(url);
  } catch (error) {
    throw error;
  }
};

export const getQuizByProject = async (projectId: string) => {
  try {
    const url = `${quizUrl}/project/${projectId}`;
    return getByUrl(url);
  } catch (error) {
    throw error;
  }
};
